.gdprModal {
  .gdprModal__placeholder {
    @include screen-md-min {
      width: 80%;
      left: 10% !important;
    }

    .h3_popuptitle {
      margin: 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }
  }
}

.gdpr-consent-tabs-navigation {
  padding: 0;
  list-style-type: none;
}

// Show GDPR modal button
.show-gdpr-modal {
  footer & {
    color: inherit !important;
    font-size: $font-size;
    cursor: pointer;
    padding: 0;
    background: transparent;
    border: none;
    position: relative;
    display: inline-block;
    line-height: normal;

    &:hover {
      background: transparent;
    }
  }
}