#order-detail {
  .account-form {
    margin-bottom: $spacer / 2;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

#order-products {
  td,
  th {
    vertical-align: middle;
  }
}

#order-infos {
  td {
    border-bottom: $border-width solid $border-color;
    //padding: $spacer / 5;
  }

  tr {
    &:last-of-type {
      td {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

// Tracking Number
.tracking-number {
  background-color: $bg-pink;
  border: 3px solid $brand-primary-light;

  a {
    font-weight: $font-bold;
    text-decoration: underline;
  }
}

.order-detail-addresses {
  .address {
    padding: $spacer / 2;
    background-color: $body-bg;
    border-radius: $border-radius;
    border: $border-width solid $border-color;
    margin-bottom: $spacer / 2;
    display: block;
    width: 100%;

    @include screen-sm-min {
      margin-bottom: 0;
    }

    p,
    h4,
    h6 {
      @include screen-sm-min {
        margin: 0;
      }
    }
  }
}

#order-history {
  ul {
    padding: 0;
    margin: 0;
    font-size: 0;

    li {
      list-style-type: none;
      width: 50%;
      display: inline-block;
      font-size: $font-size;
      position: relative;
      padding: ($spacer / 4) 0;
      vertical-align: top;
      padding-left: $spacer;

      &:first-child {
        &:before,
        &:after {
          display: none;
        }
      }

      &:nth-child(3) {
        &:after {
          top: 50% !important;
        }
      }

      &:nth-child(odd) {
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          height: $spacer / 2;
          width: $spacer / 2;
          border-radius: 50%;
          background-color: $brand-primary;
          border: $border-width * 2 solid darken($brand-primary, 5%);
          z-index: $z-index2;
        }

        &:after {
          content: "";
          position: absolute;
          left: $spacer / 4;
          top: 0;
          bottom: 0;
          width: 1px;
          background-color: $border-color;
          z-index: $z-index1;
        }
      }

      &:last-child,
      &:nth-last-child(2):nth-child(odd) {
        &:after {
          bottom: 50%;
        }
      }
    }
  }
}

#order_products_table {
  padding: 0;
  margin: 0;

  li {
    border-bottom: $border-width solid $border-color;
    padding: $spacer / 2 0;
    list-style-type: none;

    &:last-of-type {
      border-bottom: 0;
    }

    .product-name {
      p {
        font-family: $font-family-questa;
        color: $brand-primary;
        font-size: $h3-font-size;
        font-weight: $font-bold;
        margin: 0;
      }

      span {
        display: block;
        clear: both;
        color: $gray;
        font-size: $font-size * 0.9;
      }
    }

    .product-price {
      text-align: right;

      p {
        margin: 0;
      }
    }

    .product-quantity {
      text-align: center;

      p {
        margin: 0;
      }
    }

    .product-image {
      img {
        display: block;

        @include screen-md-max {
          width: 100%;
        }
      }
    }
  }
}

.order-message-form {
  margin-top: $spacer;
  padding: $spacer / 2;
  background-color: $bg-light;
}

.messages {
  max-height: 360px;
  overflow-y: scroll;
  border: $border-width solid $border-color;
  padding: $spacer / 2;
  border-radius: $border-radius;

  .message-timestamp {
    display: block;
    text-align: center;
    font-weight: bold;
  }

  .message-name {
    display: block;
    clear: both;
    font-style: italic;
    margin: 0 5px;
    color: darken($border-color, 40%);
  }

  table {
    tr {
      &:last-of-type {
        .message {
          margin-bottom: 0;
        }
      }
    }
  }

  .message {
    border-radius: $border-radius;
    padding: $spacer / 4;
    display: inline-block;
    margin-bottom: $spacer / 2;
    min-width: 45%;

    &--employee {
      background-color: $body-bg;
    }

    &--customer {
      background-color: $brand-primary;
      color: $white;
    }

    > span {
      display: block;
      text-align: left;
    }

    @include screen-xs {
      font-size: $font-size * 0.9;
      min-width: 75%;
    }
  }
}
