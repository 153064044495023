$color_1: #FFF;
$color_2: #626262;
$color_3: #585858;
$color_4: #000000;
$background_color_1: rgb(243, 243, 243);
$background_color_2: rgba(255, 255, 255, 0.73);

/** If an image is wrapped in a link  */
/** Product linked slide */
.prestablog_slide {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin: 0 0 25px;
  padding: 0;
  position: relative;
  //float: left;
  background: $white;
  //box-shadow: #ccc 0 0 5px;
  //border-radius: 5px;
  border: $border;

  .nivoSlider {
    a.nivo-imageLink {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border: 0;
      padding: 0;
      margin: 0;
      z-index: 6;
      display: none;
      overflow: hidden;
      height: 100%;
    }

    img {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      max-width: none;
    }

    position: relative;
  }

  .nivo-main-image {
    display: block !important;
    position: relative !important;
    width: 100% !important;
  }

  .nivo-slice {
    display: block;
    position: absolute;
    z-index: 5;
    height: 100%;
    top: 0;
  }

  .nivo-box {
    display: block;
    position: absolute;
    z-index: 5;
    overflow: hidden;

    img {
      display: block;
    }
  }

  .nivo-controlNav {
    text-align: center;
    position: absolute;
    top: 15px;
    right: 15px;
    height: 15px;
    z-index: 8;

    a {
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 50%;
      display: inline-block;
      vertical-align: top;
      height: 15px;
      overflow: hidden;
      width: 15px;
      cursor: pointer;
      text-indent: -9999px;
      margin-right: 5px;

      //&:hover {
      //  background: #fff;
      //  border: 2px solid #333;
      //  margin-bottom: -2px;
      //}
    }

    a.active {
      background: rgba($white, 0.7);
    }
  }

  .nivo-directionNav {
    .nivo-prevNav {
      background: url("#{$assets_dir}img/controls.png") top left no-repeat;
      float: left;
      left: 10px;
      top: 45%;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }
    }

    .nivo-nextNav {
      background: url("#{$assets_dir}img/controls.png") top right no-repeat;
      float: right;
      right: 10px;
      top: 45%;
      opacity: 0.7;

      &:hover {
        opacity: 1
      }
    }

    a {
      position: absolute;
      cursor: pointer;
      z-index: $z-index1;
      height: 32px;
      width: 35px;
      text-indent: -9999px;
    }
  }

  &:hover {
    .nivo-directionNav {
      a {
        opacity: 1
      }
    }
  }

  .nivo-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    width: 100%;
    color: $white;
    font-size: $h5-font-size;
    font-weight: $font-bold;
    z-index: 8;
    text-align: center;
  }
}

.productslinks {
  .nivoSlider {
    img {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      max-width: none;
    }

    position: relative;
  }

  .nivo-main-image {
    display: block !important;
    position: relative !important;
    width: 100% !important;
  }

  .nivo-slice {
    display: block;
    position: absolute;
    z-index: 5;
    height: 100%;
    top: 0;
  }

  .nivo-box {
    display: block;
    position: absolute;
    z-index: 5;
    overflow: hidden;

    img {
      display: block;
    }
  }

  float: left;
  position: relative;
  padding: 5px 5px 10px;
  width: auto;
  text-align: center;
  margin-bottom: 5px;
}

#blog_product_linked {
  float: left;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
  background-color: $background_color_1;
  padding: 10px 15px 15px;
  border: 1px solid rgb(233, 233, 233);

  h3 {
    padding-bottom: 10px;
    border-bottom: 1px dotted #CFCFCF;
    color: $color_2;
  }
}

.page-product-box {
  float: left;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
  background-color: $background_color_1;
  padding: 10px 15px 15px;
  border: 1px solid rgb(233, 233, 233);
  margin-top: 10px;

  .page-product-heading {
    padding-bottom: 10px;
    border-bottom: 1px dotted #CFCFCF;
    color: $color_2;
  }

  a {
    color: $color_3;
  }
}

a {
  .wrap_blog {
    div {
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      position: relative;
      height: 100%;
      min-height: 100%;

      .product_name_blog {
        position: absolute;
        left: 0;
        text-align: center;
        padding: 0;
        display: flex;
        height: 100%;
        width: 100%;
        background-color: $background_color_2;
        opacity: 0;
        font-weight: bold;
      }
    }

    .product_name_blog {
      .titre_product_blog {
        text-align: center;
        margin: auto;
        font-size: 120%;
        color: $color_4;
      }
    }
  }

  .productslinks {
    img {
      padding: 10px;
      width: 100%;
      height: auto;
      border: 1px solid rgba(237, 237, 237, 1.00);
    }
  }

  &:hover {
    .productslinks {
      img {
        opacity: 1;
      }
    }

    .wrap_blog {
      .product_name_blog {
        opacity: 1;
        padding: 10px;
      }
    }
  }
}


// Blog list pages
#blog_list_1-7 {
  height: auto !important;
  padding: 0;
  position: relative !important;

  li.blog-post {
    list-style-type: none;
    margin: 0 0 15px;

    .block_top {
    }

    .block_bas {
      h3 {
        margin: 0 0 15px;

        @include screen-sm-max {
          margin: 15px 0 10px;
        }
      }
    }

    .block_cont {
      border: $border;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
}


// Individual Blog Posts
#prestablogfront {

}


// Blog-wide styling
.info_blog {
  background-color: $bg-light;
  font-size: 0.7rem;
  line-height: normal;
  margin-bottom: 15px;
  padding: 10px;

  a {
    font-weight: $font-bold;
  }
}

.post_count {
  @include screen-sm-max {
    margin: 15px 0;
  }
}

.title_block {
  background-color: #f1f1f1;
  padding: 10px;
}

// Category Filter on main content area
#categoriesFiltrage {
  display: none;
}


// Pagination
.prestablog_pagination {
  span, a {
    background-color: $bg-light;
    font-weight: $font-bold;
    padding: 3px;
    min-width: 30px;
    display: inline-block;
    vertical-align: top;
    text-align: center;

    &.current {
      background-color: $brand-primary;
      color: $white;

      &:hover {
        background-color: darken($brand-primary, 10%);
      }
    }
  }

  a {
    &:hover {
      background-color: darken($bg-light, 10%);
      cursor: pointer;
    }
  }

  span.more {
    background-color: transparent;
    min-width: auto;
  }
}

// Left Column widgets
#left-column {
  // Categories Widget
  .block-categories {
    border-bottom: $border;
    margin-bottom: $spacer / 2;
    padding-bottom: $spacer / 2;

    ul {
      padding: 0;

      li {
        list-style-type: none;
        margin: 0 0 5px;

        span {
          font-size: 0.7rem;
        }
      }
    }
  }

  // Search Widget
  #prestablog_search {
    border-right: none;
  }

  .button-search {
    padding: 0 5px;
  }

  // Latest Posts Widget
  #prestablog_lastliste {
    margin-bottom: 0;
    padding: 0;

    li {
      list-style-type: none;
      margin: 0 0 10px;

      a {
        display: inline-block;
      }

      img {
        margin: 0 15px 15px 0;
        min-width: 40px;
      }

      p {
        line-height: normal;
        margin: 0;
      }
    }
  }
}