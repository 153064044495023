.breadcrumb {
  font-size: 0.85em;
  margin: 0 0 ($spacer / 2);
  line-height: 1.4em;

  @include screen-md-max {
    margin: 5px 0 15px 0;
  }
  ol {
    margin: 0;
    padding: 0;

    li {
      &:last-child {
        a {
          span {
            color: $brand-primary;
            font-weight: $font-bold;
          }
        }
      }
      a {
        &:hover {
          text-decoration:none;
        }
      }
    }
  }

  &__crumb {
    list-style-type: none;
    display: inline-block;
    vertical-align: top;

    &:last-of-type {
      &:after {
        display: none;
      }
    }

    &:after {
      content: '>';
      display: inline-block;
      padding-left: $spacer / 8;
      padding-right: $spacer / 8;
      color: $gray;
    }

    a {
      span {
        color: $gray;

        &:hover {
          color: $brand-primary;
        }
      }
    }
  }
}
