.social-sharing {
  h4 {
    color: $brand-primary;
    font-family: $font-family-jenna;
    font-size: $h1-font-size;
    font-style: italic;
    font-weight: $font-semi-bold;
    margin: $spacer 0 ($spacer / 2);
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin: 0 10px 0 0;

      a {
        width: 35px;
        height: 35px;

        &:before {
          background: url("#{$assets_dir}img/icons/facebook-share-icon.svg") top left no-repeat;
          background-size: cover;
          content: ' ';
          display: block;
          width: 100%;
          height: 100%;
        }

        span {
          display: none;
        }
      }

      &.twitter {
        a:before {
          background: url("#{$assets_dir}img/icons/twitter-share-icon.svg") top left no-repeat;
        }
      }

      &.pinterest {
        a:before {
          background: url("#{$assets_dir}img/icons/pinterest-share-icon.svg") top left no-repeat;
        }
      }
    }
  }

  //// Show/hide relevant module output on desktop and mobile
  //// Desktop
  //.product-additional-info & {
  //  @include screen-sm-max {
  //    display: none;
  //  }
  //}
  //
  //// Mobile
  //.product-information & {
  //  margin: ($spacer * 1.75) 0;
  //  text-align: center;
  //
  //  @include screen-md-min {
  //    display: none;
  //  }
  //}
}