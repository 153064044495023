.delivery-options {
  .delivery-option {
    padding: $spacer / 2;
    background-color: $body-bg;
    border-radius: $border-radius;
    border: $border-color solid $border-width;
    margin-bottom: $spacer / 2;
    width: 100%;
    display: block;
    line-height: normal;

    .custom-radio {
      display: block;
      margin: 0;

      label {
        margin-bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    > label {
      .row {
        align-items: center;
        justify-content: center;
      }
    }
  }
}

// Gift Wrapped
.gift-wrapped {
  margin-top: $spacer / 2;
  padding: $spacer / 2;
  background-color: $bg-light;
  border: $border;

  h2 {
    //margin-bottom: 5px;
  }

  .js-gift-checkbox + label.gift-wrapped-label {
    border: $border;
    padding: 10px 10px 10px 50px;
  }

  .custom-checkbox [type=checkbox]:checked ~ label.gift-wrapped-label {
    border: 1px solid $brand-primary;
  }

  .custom-checkbox [type=checkbox] + label:before,
  .custom-checkbox [type=checkbox] + label:after {
    left: 15px;
  }
}