.nav {
  &--sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  &__mobile {
    background-color: $white;
    pointer-events: all;
    display: none;

    @include screen-md-max {
      display: block;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    @include transitionType();

    &--visible {
      opacity: 1;
      visibility: visible;
    }
  }

  &__menu {
    @include screen-md-max {
      border-right: $border-width solid $border-color;
      margin: 0;
      padding: 0;
      top: 0;
      bottom: 0;
      overflow-y: scroll;
      background-color: $white;
      width: 280px;
      z-index: $z-index5;
      @include transitionType(left);
      position: absolute;
      left: 280px;

      &[data-depth="0"] {
        left: -280px;
        position: fixed;
      }
    }
  }

  &__next {
    display: none;

    @include screen-md-max {
      display: inline-block;
    }
  }

  &__link {
    &--buttons {
      display: none;
    }

    @include screen-md-max {
      display: block;
      line-height: $spacer;
      padding: 0 $gutter;
      border-bottom: $border-width solid $border-color;

      a {
        display: flex;
        justify-content: space-between;
      }

      &--buttons {
        display: flex;
        justify-content: flex-end;

        &-multiple {
          justify-content: space-between;
        }
      }
    }
  }
}