.homeimageblock {
  margin-bottom: $spacer;

  .image_block {
    margin-bottom: 15px;

    @include screen-sm-min {
      &:first-of-type {
        padding-right: 7.5px;
      }

      &:last-of-type {
        padding-left: 7.5px;
      }
    }

    img {
      border: $border-width solid $brand-primary;
    }
  }

}