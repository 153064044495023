.homeslider-container {
  border: 1px solid $brand-primary;
  float: none;
  margin-bottom: 0;
  position: relative;

  @include screen-md-max {
    text-align: center;
  }

  .homeslider {
    position: relative;
    overflow: hidden;
    max-height: 380px;
    margin: 0;
    padding: 0;
    list-style-type: none;

    .slide {
      a {
        display: block;
      }
    }

    .caption {
      display: block;
      position: absolute;
      z-index: 2;
      font-size: 1em;
      color: #fff;
      right: 0;
      bottom: 0;
      padding: $spacer;
      margin: 0;
      width: 45%;
      height: 100%;

      @include screen-lg-min {
        text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
      }

      @include screen-md-max {
        text-align: left;
        position: relative;
        left: 0;
        padding: 15px 15px 0;
        width: 100%;
        color: $font-color;
      }

      h2,
      h3 {
        font-family: $font-family-questa;

        @include screen-lg-min {
          font-size: 2rem;
          line-height: 1em;
        }
      }

      p {
        @include screen-lg-min {
          font-size: $h6-font-size;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .homeslider_nav_wrapper {
    position: absolute;
    left: 0;

    @include screen-lg-min {
      width: 100%;
      top: 50%;
      z-index: 5;
    }

    @include screen-md-max {
      position: relative;
      display: inline-block;
      text-align: center;
      margin-top: 25px;
    }
  }

  .homeslider_nav {
    &.prev,
    &.next {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      z-index: $z-index1;
      overflow: hidden;
      text-decoration: none;

      @include screen-lg-min {
        position: absolute;
        top: 50%;
        opacity: 0.7;
        height: 60px;
        width: 40px;
        background-color: rgba(0, 0, 0, 0.7);
        margin-top: -30px;

        &:hover {
          opacity: 1;
        }
      }

      @include screen-md-max {
        background-color: $brand-primary;
        height: 25px;
        width: 25px;
        @include border-radius(50%);
      }

      i {
        font-size: 35px;
        line-height: 60px;
        color: $white;

        @include screen-md-max {
          font-size: 20px;
          line-height: 25px;
        }
      }
    }

    &.prev {
      @include screen-lg-min {
        left: 0;
      }

      @include screen-md-max {
        float: left;
        margin-right: 20px;
      }
    }

    &.next {
      @include screen-lg-min {
        right: 0;
      }

      @include screen-md-max {
        float: right;
        margin-left: 20px;
      }
    }
  }

  // Bullet Navigation
  .homeslider_tabs {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-align: center;

    @include screen-lg-min {
      display: none;
    }

    @include screen-md-max {
      display: inline-block;
      margin: 5px auto 15px;
    }

    li {
      display: inline-block;
      vertical-align: top;
      width: 15px;
      height: 15px;
      margin: 0 5px;
      background-color: $brand-primary-light;
      @include border-radius(50%);
      text-indent: -10000px;

      &.homeslider_here {
        background-color: $brand-primary;
      }

      a {
        display: block;
      }
    }
  }
}