$font-family-jenna:     'JennaSue', Arial, Helvetica, sans-serif !important;
$font-family-droid:     'droid_sansregular', Arial, Helvetica, sans-serif;;
$font-family-questa:    'questa_granderegular', Arial, Helvetica, sans-serif;
$font-family-icon:       'Material Icons';


$brand-primary:         #ec8399 !default;
$brand-primary-light:   lighten($brand-primary, 15%);
$brand-primary-dark:    darken($brand-primary, 10%);
$brand-secondary:       #eee !default;
$brand-tertiary:        #51bcb9 !default;
$brand-quaternary:      null !default;
$white:                 #ffffff !default;
$body-bg:               #fff !default;
$gray:                  #9a9a9a !default;
$transparent:           transparent !default;

$notification-warning: #fcf8e3 !default;
$notification-success: #dff0d8 !default;
$notification-danger: #f2dede !default;
$notification-info: #d9edf7 !default;

$box-shadow-light: null !default;
$box-shadow-medium: null !default;
$box-shadow-heavy: null !default;

// Background Colours
$bg-light: #f9f9f9;
$bg-medium: #eee;
$bg-dark: #666;
$bg-pink: #fff3f6;

$border-color: #d0d0d0;
$border-width: 1px !default;
$border-color-medium: #eee;
$border: $border-width solid $border-color;
$border-dashed: $border-width dashed $border-color;
$border-radius: 0px !default;
$border-pill: 500px;

$font: $font-family-droid;
$font-line-height: 25px !default;
$font-color: #666666 !default;
$font-size: 0.9rem !default;
$font-light: 100 !default;
$font-reg: 400 !default;
$font-semi-bold: 500 !default;
$font-bold: 600 !default;
$font-weight: $font-reg !default;

$h1-font-size: $font-size * 2.2 !default;
$h2-font-size: $font-size * 1.85 !default;
$h3-font-size: $font-size * 1.5 !default;
$h4-font-size: $font-size * 1.4 !default;
$h5-font-size: $font-size * 1.2 !default;
$h6-font-size: $font-size * 1.1 !default;

$link-color: #666 !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 10%) !default;
$link-hover-decoration: none !default;

$spacer: 40px;
$gutter: 5px;
$element-height: 35px;

$z-index1: 5 !default;
$z-index2: 10 !default;
$z-index3: 15 !default;
$z-index4: 20 !default;
$z-index5: 25 !default;

$assets_dir: '/themes/base-theme-child/assets/';
$theme_dir: '/themes/base-theme-child/';

