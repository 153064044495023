.order-item {
  .order-item-inner {
    position: relative;
    padding: 20px 0;

    h6 {
      margin: 0 0 $spacer / 2 0;
    }
  }

  @media (min-width: 992px) {
    &:nth-child(odd) {
      .order-item-inner {
        border-bottom: $border-width solid $border-color;

        &:after {
          content: '';
          position: absolute;
          right: -15px;
          top: 0;
          bottom: 0;
          width: 1px;
          background-color: $border-color;
        }
      }
    }

    &:nth-child(even) {
      .order-item-inner {
        border-bottom: $border-width solid $border-color;
      }
    }

    &:nth-last-child(-n+2) {
      .order-item-inner {
        border-bottom: 0;
      }
    }
  }
}
