@font-face {
  font-family: 'droid_sansregular';
  src: url('#{$assets_dir}fonts/DroidSans-webfont.eot');
  src: url('#{$assets_dir}fonts/DroidSans-webfont.eot?#iefix') format('embedded-opentype'), url('#{$assets_dir}fonts/DroidSans-webfont.woff') format('woff'), url('#{$assets_dir}fonts/DroidSans-webfont.ttf') format('truetype'), url('#{$assets_dir}fonts/DroidSans-webfont.svg#droid_sansregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'questa_granderegular';
  src: url('#{$assets_dir}fonts/2-Questa_Grande_Regular-webfont.eot');
  src: url('#{$assets_dir}fonts/2-Questa_Grande_Regular-webfont.eot?#iefix') format('embedded-opentype'), url('#{$assets_dir}fonts/2-Questa_Grande_Regular-webfont.woff2') format('woff2'), url('#{$assets_dir}fonts/2-Questa_Grande_Regular-webfont.woff') format('woff'), url('#{$assets_dir}fonts/2-Questa_Grande_Regular-webfont.ttf') format('truetype'), url('#{$assets_dir}fonts/2-Questa_Grande_Regular-webfont.svg#questa_granderegular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'JennaSue';
  src: url('#{$assets_dir}fonts/jennasue-webfont.eot');
  src: url('#{$assets_dir}fonts/jennasue-webfont.eot?#iefix') format('embedded-opentype'), url('#{$assets_dir}fonts/jennasue-webfont.ttf') format('truetype'), url('#{$assets_dir}fonts/jennasue-webfont.svg#JennaSue') format('svg');
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('https://fonts.gstatic.com/s/materialicons/v41/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2') format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  vertical-align: top;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';

  &--events-none {
    pointer-events: none;
  }

  &--brand-primary {
    color: $brand-primary;
  }

  &--brand-secondary {
    color: $brand-secondary;
  }
}
