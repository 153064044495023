.ps-social-follow {
  @include screen-sm-max {
    margin-top: $spacer / 2;
  }

  h5 {
    font-family: $font-family-jenna;
    font-size: $h2-font-size;
    margin: 0 0 15px;

    @include screen-sm-max {
      display: none;
    }
  }

  ul {
    display: inline-block;
    margin-right: 5px;

    li {
      display: inline-block;
      margin-right: 5px;

      a {
        span {
          display: none;
        }

        &:before {
          content: " ";
          display: block;
          height: 30px;
          width: 30px;
          //color: #fff;
        }
      }

      &.facebook {
        a {
          &:before {
            background: url('#{$assets_dir}img/icons/facebook-share-icon-white.svg') no-repeat;
            @include screen-sm-max {
              background: url('#{$assets_dir}img/icons/facebook-share-icon.svg') no-repeat;
            }
          }
        }
      }

      &.twitter {
        a {
          &:before {
            background-image: url('#{$assets_dir}img/icons/twitter-share-icon-white.svg');
            @include screen-sm-max {
              background-image: url('#{$assets_dir}img/icons/twitter-share-icon.svg');
            }
          }
        }
      }

      &.youtube {
        a {
          &:before {
            background-image: url('#{$assets_dir}img/icons/youtube-share-icon-white.svg');
            @include screen-sm-max {
              background-image: url('#{$assets_dir}img/icons/youtube-share-icon.svg');
            }
          }
        }
      }

      &.pinterest {
        a {
          &:before {
            background-image: url('#{$assets_dir}img/icons/pinterest-share-icon-white.svg');
            @include screen-sm-max {
              background-image: url('#{$assets_dir}img/icons/pinterest-share-icon.svg');
            }
          }
        }
      }

      &.instagram {
        a {
          &:before {
            background-image: url('#{$assets_dir}img/icons/instagram-share-icon-white.svg');
            @include screen-sm-max {
              background-image: url('#{$assets_dir}img/icons/instagram-share-icon.svg');
            }
          }
        }
      }
    }
  }

  // Fixed position social sharing on right hand side of screen
  &.fixed {
    position: fixed;
    right: 0;
    top: 50%;
    width: 50px;
    padding: 10px 5px 5px;
    text-align: center;
    background-color: $white;
    z-index: $z-index2;

    @include screen-md {
      top: 30%;
    }

    h5 {
      margin-bottom: 5px;
      color: $brand-primary;
    }

    ul {
      margin: 0;
      background: $brand-primary;
      @include border-radius(10px);

      li  {
        margin: 0;
        padding: 10px 0 0;

        &:last-of-type {
          padding-bottom: 5px;
        }

        a {
            @include scaleOnHoverPseudoBefore(.5s, 1.3);
        }
      }
    }
  }
}