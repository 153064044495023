// Homepage Testimonials
#testimonal_block {
  background: $bg-pink;
  padding: 30px 15px;
  margin-bottom: 40px;

  .home-testimonials-wrapper {
    @include screen-sm-max {
      margin-top: $spacer;
    }

    .button {
      @include screen-sm-min {
        &:first-of-type {
          margin-right: 10px;
        }
      }

      @include screen-xs {
        margin-bottom: 10px;
      }
    }
  }

  h2.testimonial_title {
    font-size: 2.5rem;
    line-height: 1.2em;
    margin-bottom: $spacer;
    color: $font-color;

    @include screen-sm-max {
      margin-bottom: 10px;
      font-size: 1.85rem;
    }
  }

  h3.testimonial_thanks {
    font-family: $font-family-jenna;
    font-size: 2rem;
    line-height: 1.2em;
    //font-style: italic;
    color: $brand-primary;
    display: inline;
    position: relative;

    &:after {
      content: "";
      display: block;
      height: 25px;
      width: 25px;
      position: absolute;
      right: -25px;
      bottom: -20px;
      background: url('#{$assets_dir}img/review-kiss.svg') no-repeat;

      @include screen-sm-max {
        margin-bottom: 10px;
        font-size: 2rem;
        right: -15px;
        bottom: -35px;
      }
    }
  }

  .testimonial {
    margin-bottom: $spacer / 2;
    padding-bottom: $spacer / 2;
    border-bottom: $border;

    h4 {
      .stars {
        margin-left: 10px;

        @include screen-sm-max {
          display: block;
          margin: 10px 0 0 0;
        }

        img {
          width: 22px;
          margin-top: -5px;
        }
      }
    }
  }

  ul {
    text-align: center;

    li {
      list-style: none;
      display: inline-block;

      a {
        color: $white;
      }
    }
  }

  .testimonial_angela {
    img {
      margin: 0 auto;
      display: block;
      padding: 40px 0;
    }
  }
}

// Reviews List Page
.b-search-items input.txt {
  height: $element-height !important;
}

.testimonials-items {
  padding: 15px !important;
}

#storereviews-list {
  .shop-review {
    blockquote {
      border-left: 5px solid $border-color;
      padding-left: 10px;
    }
  }

  .shop-review-star-wrapper {
    margin-left: 15px;
  }

  .shop-review-date {
    background-color: $bg-light;
    font-size: 0.8rem;
    padding: 3px 10px;
    display: inline-block;

    i {
      font-size: $font-size;
    }
  }

  .admin-reply-on-testimonial {
    padding: 10px;
    background-color: lighten($notification-info, 5%);
    margin: ($spacer / 2) 0 0 ($spacer * 0.75);

    .owner-date-reply {
      color: #3e708a;
      background-color: $notification-info;
      padding: 0 10px;
    }

    blockquote {
      border-left-color: darken($notification-info, 20%);
      margin: 10px 0 0;
    }

    i {
      font-size: $font-size;
      margin-right: 10px;
    }
  }

  .foot_center {
    //float: left;
    font-size: $font-size
  }

  .compareTableNew {
    .info {
      padding: 20px;
    }
  }
}

.toolbar-bottom {
  nav {
    .pagination {
      padding: 0;
      list-style-type: none;

      li {
        display: inline-block;
        margin: 0 5px;

        &.active {
          font-weight: $font-bold;
        }
      }
    }
  }
}