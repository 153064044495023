.page {
  margin-bottom: $spacer;
  padding-top: 15px;

  @include screen-md-max {
    padding-top: 105px;
  }

  @include screen-sm-max {
    padding-top: 80px;
  }

  @include screen-xs {
    padding-top: 65px;
  }

  @media (max-width: 420px) {
    padding-top: 55px;
  }

  #checkout & {
    @include screen-lg-min {
      padding-top: $spacer * .75;
    }
  }
}

#index {
  .page {
    margin-bottom: $gutter;
  }
}

#cms {
  h1 {
    text-transform: lowercase;
    margin-bottom: 1.2em;
  }

  h2 {
    margin: 1rem 0 0.5rem;
  }

  #content {
    a {
      font-weight: $font-bold;
      text-decoration: underline;
    }

    img {
      @include screen-xs {
        width: 100%;
      }
    }
  }
}

// Maintenance Page
body#maintenance {
  .maintenance-wrapper {
    padding: $spacer / 2;
    @include box-shadow(0, 0, 10px, 0, 0.5);

    .logo {
      img {
        @include screen-sm-max {
          max-width: 280px;
        }
      }
    }

    h1 {
      margin: 20px 0;
      padding: 15px;
      border: $border;
      border-left: none;
      border-right: none;
      font-size: $h3-font-size;
    }

    a {
      font-weight: $font-bold;
    }
  }
}