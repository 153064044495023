textarea {
  appearance: none;
  -webkit-appearance: none;
  border: $border-width solid;
  border-color: $border-color;
  min-height: $element-height;
  display: block;
  width: 100%;
  border-radius: $border-radius;
  padding: 10px;
  outline: 0;

  &:active, &:focus {
    // border-color: darken($border-color, 10%);
    border-color: $brand-primary;
  }
}
