#index {
  .footer {
    border-top: none;
    padding-top: 0;
  }
}

.footer {
  color: #fff;
  border-top: 10px solid #fff3f6;
  padding-top: $spacer;

  @include screen-sm-max {
    background: #fff;
    padding-top: $spacer;
    color: #666;
  }

  a {
    color: #fff;

    @include screen-sm-max {
      color: #666;
    }
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;

      @include screen-sm-max {
        text-align: center;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__main {
    //background:$brand-primary;
    background: url('#{$assets_dir}img/footer_bg_faded.png') top right no-repeat $brand-primary;
    padding: 30px 0;

    @include screen-sm-max {
      background: none;
      background-color: #fff;
      padding-top: 0;
    }
  }

  .boxed_footer {
    @include screen-sm-max {
      border: 1px solid #666;
      padding: 30px 10px 0;
    }
  }

  .copyright_footer {
    margin: ($spacer / 2) 0;

    p {
      width: 100%;

      span {
        text-align: right;
        float: right;

        @include screen-sm-max {
          text-align: center;
          float: left;
          width: 100%;
        }
      }

      @include screen-sm-max {
        text-align: center;
      }
    }
  }
}
