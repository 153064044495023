.contact__info {
  // Contact Page
  #contact .page__content & {
    background-color: $bg-light;
    padding: $spacer;

    h3 {
      font-size: $h4-font-size;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: $border-width dashed $border-color;
    }

    .contact__business-name {
      margin-bottom: 10px;
    }

    .contact__address {
      margin-bottom: 0;
    }

    .contact__telephone {
      margin-top: 10px;
    }

    .contact__email {
    }
  }

  // Footer
  .footer & {
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 15px;
    border: $border-width dashed lighten($brand-primary, 5%);
    border-left: none;
    border-right: none;
    text-align: center;

    @include screen-sm-max {
      border-color: $border-color-medium;
    }

    h3 {
      display: none;
    }

    p {
      margin-bottom: 0;
    }

    .contact__business-name,
    .contact__address-wrapper,
    .contact__address {
      display: inline-block;
    }

    .contact__telephone,
    .contact__email {
      display: none;
    }
  }
}




