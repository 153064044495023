#featured_products {
  h2 {
    margin: ($spacer * 0.75) 0;
    text-align: center;

    @include screen-sm-max {
      margin: ($spacer / 2) 0;
    }
  }

  .product-miniature {
    margin-bottom: $spacer;
  }
}