//#category {
//  .page {
//    border-bottom: 10px solid #fff3f6;
//  }
//}

.category_top {
  margin-bottom: $spacer;

  @include screen-sm-max {
    margin-bottom: $spacer / 2;
  }

  h1 {
    margin-top: 0;
  }

  .category-cover {
    img {
      @include screen-sm-max {
        border: $border;
      }
    }
  }
}

// Sort Options
//.products-sort-order {
//  padding-bottom: 10px;
//  margin-bottom: 10px;
//
//  @include screen-sm-max {
//    text-align: center;
//  }
//
//  span {
//    display: inline-block;
//    margin-right: 10px;
//  }
//
//  select {
//    display: inline-block;
//    width: auto;
//  }
//}

.products-selection {
  border-top: 1px solid $brand-primary;
  margin-bottom: $spacer;
  padding-top: 5px;
}

#js-product-list {
  padding-bottom: $spacer;
}


// Individual Products Styling
.product-miniature {
  margin-bottom: $spacer * 2;

  @include screen-md-min {
    &:before {
      content: '';
      position: absolute;
      top: -10px;
      left: -10px;
      right: -10px;
      bottom: -10px;
      background: #FFF;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
      display: none;
    }

    &:hover {
      z-index: $z-index1;

      &:before {
        display: block;
      }
    }
  }

  @include screen-md-max {
    margin-bottom: $spacer;
  }

  img {
    //width: 100%;
  }

  .product-thumbnail {
    position: relative;
    display: block;
  }

  .product_thumbnail {
    margin-bottom: 25px;

    @include screen-sm-max {
      margin-bottom: 0;
    }
  }

  .product-flags {
    @include screen-sm-max {
      position: relative;
      top: 10px;
      left: 0;

      li {
        border-radius: 0;
        height: auto;
        width: auto;
        padding: 3px 5px;

        span {
          display: inline-block;
          height: auto;
          width: auto;
          font-weight: $font-bold;
        }
      }
    }
  }

  .product_info {
    @include screen-sm-max {
      padding-left: 0;
    }
  }

  h2 {
    text-align: center;

    @include screen-md-max {
      text-align: left;
    }
  }

  h3.product-name {
    margin: 0 0 15px;
    background-color: $white;

    a {
      @include textHover($brand-primary);
    }

    @include screen-sm-max {
      margin: 0 0 10px;
    }

    @include screen-xs {
      font-size: $h3-font-size;
    }
  }

  p {
    margin-bottom: 5px;

    &.description-top {
      font-weight: $font-bold;

      @include screen-sm-max{
        line-height: normal;
      }
    }

    &.description-bottom {
      @include screen-sm-max {
        line-height: normal;
      }
    }
  }

  .product-actions-wrapper {
    margin-top: 20px;
    min-height: 45px;
    position: relative;

    @include screen-xs {
      margin-top: 10px;
    }
  }

  .product-price-and-shipping {
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;

    @include screen-sm {
      display: block;
      margin-top: 15px;
    }

    .price {
      font-size: $h4-font-size;
      font-weight: $font-bold;
    }
  }

  .product-list-actions {
    display: inline-block;
    vertical-align: top;
    margin-left: $spacer / 2;

    @include screen-sm {
      margin: 10px 0 0;
      display: block;
    }

    @include screen-xs {
      margin-left: 15px;
    }
  }
}

.images-container {
  position: relative;
}

// Pagination
.pagination-summary {
  @include screen-sm-max {
    text-align: center;
  }
}

nav.pagination {
  text-align: right;

  @include screen-sm-max {
    text-align: center;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin: 0 3px;
      vertical-align: top;
    }
  }
}