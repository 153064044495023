.g_customfields_content_box {
  margin-top: $spacer;
  background-color: $bg-light;
  padding: $spacer / 2;

  h2 {
    margin-top: $spacer / 2;
  }

  // Form Group Titles and Descriptions
  .html_box {
    h3 {
      color: $brand-primary;
      font-family: $font-family-questa;
      font-size: $h4-font-size;
      margin-bottom: 10px;
    }

    .help-block {
      margin-bottom: 15px;
      font-style: italic;
      font-size: 0.8rem;
    }
  }

  .form-group {
    border-bottom: $border;
    border-bottom-style: dashed;
    margin: 0 0 ($spacer / 2) 0;
    padding: 0;

    &.input_box,
    &.select_box {
      padding-bottom: $spacer / 2;
    }

    // Form Group Headings - output as labels by module

    > label {
      color: $brand-primary;
      font-family: $font-family-questa;
      font-size: $h4-font-size;
      font-weight: $font-bold;
      text-transform: lowercase;

      &.required_label {
        position: relative;

        &:after {
          content: "*";
          color: red;
          display: inline-block;
          font-family: $font;
          margin-left: $gutter;
        }
      }
    }

    // Form Element Styling
    &.radio_box,
    &.checkbox_box {
      input {
        + label {
          display: inline;
          margin-left: 10px;
        }
      }
    }
  }

  .red-star {
    content: "*";
    color: red;
    display: inline-block;
    font-family: $font;
    font-size: $h4-font-size;
    font-weight: $font-bold;
    margin-right: $gutter;
  }

  // individual form block styling
  #g_customfields_9 {
    border-bottom: $border;
    border-bottom-style: dashed;
    margin: 0 0 ($spacer / 2) 0;
  }

  #g_customfields_2 {
    .select_box {
      display: inline-block;
      position: relative;
      border: none;
      margin: 0;
      padding: 0;

      .select-wrapper {
        clear: both;
      }

      //&:after {
      //  content: "\E313";
      //  position: absolute;
      //  top: 50%;
      //  transform: translateY(-50%);
      //  right: 10px;
      //  font-feature-settings: "liga";
      //  font-family: "Material Icons";
      //  font-size: $font-size * 1.6;
      //  line-height: $element-height;
      //  pointer-events: none;
      //}
    }
  }
}

// Styling in the Customer Account Area
#view_additional {
  .edit_additional {
    margin-bottom: $spacer / 2;
  }
}

.g_customfields_action {
  .save-skin-information {
    margin: ($spacer / 2) 0;

    i {
      margin-right: 10px;
    }
  }
}

// Hide the following content in the back office
#module-g_customfields-additional {
  #g_customfields_20,
  #g_customfields_2 {
    display: none;
  }
}