.account-form {
  background-color: $white;
  border: 3px solid $border-color;
  border-radius: $border-radius;
  padding: $spacer / 2;
}

.link-list {
  padding: 0 $spacer / 2;
  margin: 0;
  border-radius: $border-radius;
  background-color: $white;

  &-wrapper {
    border: $border-width solid $border-color;

    @include screen-md-min {
      ul {
        margin-bottom: 0;
      }
    }
  }

  li {
    list-style-type: none;
    border-bottom: $border-width solid $border-color;

    i {
      display: inline-block;
      pointer-events: none;
      line-height: 70px;
      vertical-align: top;
      margin-right: 10px;
    }

    a {
      line-height: 70px;
      display: block;
      position: relative;

      &:hover {
        color: $brand-primary;
      }

      &.active-page {
        color: $brand-primary;
      }
    }

    @include screen-md-min {
      &:last-of-type {
        border-bottom: 0;

        a {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  &-right {
    @include screen-sm-max {
      li:last-of-type {
        border-bottom: 0;
      }
    }
  }

  @include screen-lg-min {
    margin-bottom: $spacer * 0.75;
  }
}

// Back to account buttons inside account area and any specific modules
.page-customer-account,
#addresses,
#module-g_customfields-additional,
#module-gsnippetsreviews-account,
#module-ps_emailalerts-account,
#order-detail {
  .page__footer {
    margin-top: $spacer;

    ul {
      padding: 0;
      list-style-type: none;
    }
  }

  .button {
    margin: 0 15px 15px 0;

    @include small-mobile {
      margin-right: 0;
    }
  }
}

// Account Creation
.register-form {
  .custom-checkbox {
    #newsletter ~ label {
      font-weight: $font-bold;

      em {
        font-weight: $font-weight;
      }
    }
  }
}

// Birthday Date
.birthday-reason {
  background-color: $notification-warning;
  margin: -20px 0 $spacer;
  padding: 5px 10px;
}