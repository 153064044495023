input[type='text'],
input[type='number'],
input[type='tel'],
input[type='email'],
input[type='password'],
input[type='date'] {
  appearance: none;
  -webkit-appearance: none;
  border: $border-width solid;
  border-color: $border-color;
  height: $element-height;
  display: block;
  width: 100%;
  border-radius: 0;
  padding: 0 10px;
  outline: 0;

  &:active, &:focus {
    border-color: $brand-primary;
  }
}

input[type='date'] {
  width: auto;
}