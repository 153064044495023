.page-addresses {
  .address {
    border-bottom: 1px dashed $border-color;

    .address-item {
      background-color: $body-bg;
      padding: $spacer / 2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //margin-bottom: $spacer / 4;
      border-radius: $border-radius;

      @include screen-xs {
        padding: $spacer / 4;
        display: block;

        .interactive {
          display: block;
          margin: 15px 0;

          a {
            margin: 0 $spacer 0 0;
          }
        }
      }

      h5 {
        margin-bottom: 5px;
      }

      p {
        margin: 0;
      }

      span {
        a {
          margin-left: $spacer / 2;
          display: inline-block;
          vertical-align: top;

          &:hover {
            i {
              color: $brand-primary;
            }
          }

          i {
            color: darken($border-color, 10%);
          }
        }
      }
    }
  }
}
