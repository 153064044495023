// Sitewide search box styling
#search_widget {
  display: inline-block;
  position: relative;

  form {
    @include screen-lg-min {
      position: relative;
    }

    @include screen-md-max {
      background-color: $white;
      display: block !important;
      padding: 25px;
      width: 450px;
      position: fixed;
      z-index: $z-index5;
      top: 45%;
      left: 50%;
      transform: translate(-225px, -30px);
      border-radius: 10px;
    }

    @include screen-xs {
      width: 80%;
      left: 10%;
      transform: none;
      padding: 15px;
    }
  }

//   label {
//     @include screen-lg-min {
//       position: absolute;
//       z-index: $z-index1;
//     }
//   }

  input {
    display: inline-block;
    //font-family: $font-family-jenna;
    font-size: $font-size;
    border: 0;
    border-bottom: 2px solid $brand-primary;
    border-radius: 0;
    text-align: center;
    height: $element-height;
    width: calc(100% - 35px);

    // @include screen-lg-min {
    //   position: absolute;
    //   z-index: $z-index2;
    // }

    &:placeholder-shown {
      font-family: $font-family-jenna;
      font-size: $h1-font-size;
    }
  }

  .submit {
    background: url('#{$assets_dir}img/search.svg') 50% 50% no-repeat !important;
    background-size: contain;
    height: $element-height - 10px;
    width: $element-height - 10px;
    padding: 0;
    position: absolute;
    top: 5px;
    right: 0;

    @include screen-md-max {
      right: 30px;
      height: 40px;
      width: 25px;
      top: 50%;
      transform: translateY(-50%);
    }

    @include screen-xs {
      right: 15px;
    }
  }

  .search-box-overlay {
    background-color: rgba(#000, 0.85);
    content: ' ';
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 3s ease-in-out;
    cursor: pointer;
  }

  &.active {
    @include screen-md-max {
      display: block !important;

      .search-box-overlay {
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: $z-index4;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  // Desktop specific styling
  .basket_section & {
    margin-top: 15px;
  }
}

// Search results styling
.ui-autocomplete.ui-front {
  margin-top: -1px;
  padding: 0;
  border-radius: 0;
  border-color: $brand-primary;
  border-top: none;

  li {
    font-size: $font-size;

    &.ui-menu-item {
      a {
        padding: 3px 0.6em;
      }
    }

    .separator {
      font-size: 10px;
      margin: 4px 5px 0 0;
      display: inline-block;
      vertical-align: top;
    }
  }
}