#product {
  // Left Column Content

  // Product Images
  .images-container {
    img {
      width: 100%;
    }

    .product-cover {
      //border: 1px solid $brand-primary;
    }

    // Product Image Thumbnails
    .product-images {
      margin-top: 15px;
      margin-bottom: 20px;

      ul {
        margin: 0;
        padding: 0;

        @include screen-sm-max {
          text-align: center;
        }
      }

      // Individual Thumbnail List Items
      .product-images__image {
        list-style-type: none;
        display: inline-block;
        cursor: pointer;

        @include screen-md-min {
          margin: 0 15px 15px 0;
          max-width: 100px;

          &:hover {
            img {
              opacity: 1;
            }
          }
        }

        @include screen-sm-max {
          background-color: $brand-primary-light;
          margin: 0 3px;
          height: 20px;
          width: 20px;
          @include border-radius(50%)

          &--active {
            background-color: $brand-primary;
          }
        }

        img {
          border: 1px solid $brand-primary;
          pointer-events: none;
          opacity: 0.5;

          @include screen-sm-max {
            display: none;
          }
        }

        &--active {
          img {
            opacity: 1;
          }
        }
      }
    }
  }


  // Right Column Content

  // Product Name
  h1 {
    margin-top: 0;

    @include screen-sm-max {
      margin: 10px 0 30px 0;
    }
  }


  // Product Description
  #product-description-short {
    h3 {
      font-size: $h5-font-size;
    }
  }

  // Product Actions box
  .product-actions {
    background-color: $bg-light;
    padding: 15px;
    border: $border;
    margin-top: $spacer;
  }

  // Product stock
  #product-quantities,
  #product-availability {
    margin-bottom: $spacer / 2;
    font-weight: $font-bold;
    padding: 0 0 5px;

    @include small-mobile {
      text-align: center;
    }
  }

  // Product Combinations
  .product-variants {
    .product-variant-group {
      margin-bottom: $spacer;
    }

    select {
      display: inline-block;
      width: 100%;
      border: 1px solid $brand-primary;
      border-radius: 0;
    }

    label {
      font-weight: $font-bold;
      line-height: normal;
    }
  }

  // Product Quantities
  .product-quantity-wrapper {
    margin-right: $spacer;
    display: inline-block;
    vertical-align: top;

    @include screen-lg {
      margin-right: $spacer * 0.75;
    }

    @include screen-md {
      margin: 0;
      width: 49%;
      text-align: center;
    }

    @include screen-xs {
      margin: 0;
      width: 49%;
      text-align: center;
    }

    @include small-mobile {
      width: 100%;
    }
  }

  .product-quantity {
    margin: 0;

    input {
      display: inline-block;
      vertical-align: top;
      width: 50px;
      padding: 0;
      border-radius: 0;
      text-align: center;
      border-left: none;
      border-right: none;

      &[type=number]::-webkit-inner-spin-button,
      &[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      -moz-appearance:textfield;
    }

    label {
      font-weight: $font-bold;
      font-size: 12px;
      height: $element-height;
      line-height: $element-height;
      display: inline-block;
      vertical-align: top;
      margin: 0 10px 0 0;

      @include small-mobile {
        margin-left: -30px;
      }
    }

    .qty_wanted {
      display: inline-block;
      vertical-align: top;
    }

    .qty__buttons {
      height: $element-height;
      //width: 20px;
      display: inline-block;
      vertical-align: top;
      //position: relative;
      //margin-left: 3px;

      button {
        width: $element-height - 10px;
        height: $element-height;
        border-radius: 0;
        padding: 0;
        color: $white;

        i {
          //font-size: 14px;
          //font-weight: $font-bold;
          //line-height: 16px;
        }

        &.add {
          //position: absolute;
          //top: 0;
        }

        &.down {
          //position: absolute;
          //bottom: 0;
        }
      }
    }
  }

  // Product Buttons
  .product-add-to-cart {
    @include screen-sm {
      text-align: center;
    }
  }

  // Product Tabs
  .tabs {
    &__wrapper {
      margin-top: $spacer;
      margin-bottom: $spacer;
    }

    &__list {
      margin: 0;
      padding: 0;
    }

    &_contents {
      width: 100%;
      border: 1px solid $brand-primary;
      padding: 15px;
      margin-top: -1px;

      @include screen-sm-max {
        padding: 25px;
      }

      &__content {
        display: none;
        width: 100%;

        &--active {
          display: block;
        }
      }
    }

    &__tab {
      display: inline-block;
      border: 1px solid $brand-primary;
      padding: 5px;
      cursor: pointer;
      font-size: 14px;
      line-height: normal;
      margin-right: 1px;
      text-align: center;

      @include screen-xs {
        width: 100%;
        padding: 7px 5px;
        background-color: #fbf2f4;
        border-bottom: none;

        &:last-of-type {
          border-bottom: 1px solid $brand-primary;
        }
      }

      &--active {
        background: $brand-primary;
        color: #fff;
      }
    }
  }

  // Product sections - Attachments, Downloads, pack products etc

  .product-panels {
    margin-top: $spacer * 2;
  }

  .product-panel {
    margin-bottom: $spacer * 2;

    h2 {
      margin-bottom: $spacer;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        margin-bottom: $spacer / 2;
      }
    }

    label {
      display: block;
      font-weight: $font-bold;
    }
  }

  .product-customization {
    .inner {
      background-color: $bg-light;
      border: $border;
      padding: 15px;
    }
  }

  .product-attachments {
    h5 {
      margin-bottom: 0;
    }
  }

  .customised-advice {
    margin: 0 0 $spacer;
  }

  .product-customization {
    ul {
      li {
        margin-bottom: $spacer;
      }
    }

    .customization-upload {
      background-color: $notification-success;
      //color: $white;
      padding: 10px;
      margin-bottom: 15px;

      h6 {
        font-size: 0.7rem;
        margin-bottom: 10px;
        padding: 5px;
        background-color: rgba(0, 0, 0, 0.05);
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  .product-features {
    ul {
      margin: 0;
      padding: 0;
      list-style-position: inside;
    }
  }
}


// Product Flags
.product-flags {
  position: absolute;
  top: 15px;
  left: 15px;
  margin: 0;
  padding: 0;
  z-index: $z-index1;
  pointer-events: none;

  li {
    display: inline-block;
    background: $brand-primary;
    border-radius: 50%;
    font-size: 0.7rem;
    line-height: 0.85rem;
    list-style: none;
    margin: 0 10px 0 0;
    width: 50px;
    height: 50px;
    text-align: center;
    color: #fff;

    @include screen-sm-max {
      margin-right: 5px;
    }

    &.on-sale,
    &.discount {
      background-color: #78b18f;
    }

    &.online-only {
      background-color: #a88cc5;
    }

    span {
      display: table-cell;
      font-weight: $font-semi-bold;
      height: 50px;
      width: 50px;
      vertical-align: middle;
    }
  }
}


// Global Product Buttons
.product-list-actions,
.add {
  display: inline-block;

  button {
    position: relative;
    font-family: $font-family-jenna;
    @include textHover($brand-primary);
    background: transparent;
    font-size: 2rem;
    font-style: italic;
    padding: 0;
    min-height: 45px;
    font-weight: $font-weight;

    &:hover {
      img.add-to-cart-icon {
        -webkit-animation-name: wobble;
        animation-name: wobble;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
      }
    }

    img.add-to-cart-icon {
      margin-left: 5px;
      height: 100%;
      max-height: 40px;

      @include screen-lg {
        max-width: 28px;
      }

      //@include screen-xs {
      //  width: 20px;
      //}
    }
  }
}

#product {
  .add {
    @include screen-md {
      display: block;
      border-top: $border-width dashed $border-color;
      padding-top: 15px;
      text-align: center;
      margin-top: 15px;
    }

    @include screen-xs {
      display: block;
      border-top: $border-width dashed $border-color;
      padding-top: 15px;
      text-align: center;
      margin-top: 15px;
    }
  }
}

// Product Page Prices
#product {
  .product-prices {
    display: inline-block;
    margin-right: $spacer;
    vertical-align: top;

    @include screen-lg {
      margin-right: $spacer * 0.75;
    }

    @include screen-md {
      width: 49%;
      margin: 0;
      text-align: center;
    }

    @include screen-xs {
      width: 49%;
      margin: 0;
      text-align: center;
    }

    @include small-mobile {
      width: 100%;
      margin-top: $spacer / 2;
      margin-bottom: 0;
      padding-top: $spacer / 2;
      border-top: $border-width dashed $border-color;
    }

    .product-price {
      font-size: $h3-font-size;
      margin-bottom: 5px;
    }
  }
}

// Global Product Prices
.product-price {
  margin: 0;
  font-weight: $font-bold;

  #product .product-information & {
    font-size: $element-height - 5px;
    line-height: $element-height;
  }

  small {
    font-size: 0.7rem;
  }

  // Discounted Price
  &.has-discount {
  }

  .discount-percentage {
    font-size: 1rem;
  }
}

// Discounted Products - Original Price
.product-discount-wrapper {
  margin-bottom: 10px;

  @include screen-xs {
    margin-bottom: 5px;
  }
}

.product-discount {
  background-color: $brand-tertiary;
  color: $white;
  font-weight: $font-bold;
  padding: 3px 10px;
  line-height: normal;

  @include screen-md {
    display: inline-block;
  }

  @include small-mobile {
    font-size: 0.75rem;
  }

  .product-miniature & {
    font-size: 0.7rem;
  }

  .regular-price {
    margin-right: 10px;
    display: inline-block;
    vertical-align: center;
    text-decoration: line-through;
  }

  .discount-percentage {
    float: right;
  }
}