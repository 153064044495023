#conditions-to-approve {
  ul {
    padding: 0;
    margin: 0;

    li {
      list-style-type: none;
    }
  }
}

.payment-options {
  .payment-option {
    background-color: $body-bg;
    border-radius: $border-radius;
    margin-bottom: 15px;
    width: 100%;
    display: block;

    .custom-radio {
      display: block;
      margin: 0;

      label {
        margin: 0;
        width: 100%;
        display: flex;
        justify-content: left;
        align-items: center;
        padding: 20px 20px 20px 50px;
        border: $border-color solid $border-width;
      }
    }

    .custom-radio [type=radio]:checked+label,
    .custom-radio [type=radio]:not(:checked)+label {
      &:before,
      &:after {
        left: 15px;
      }
    }
    .custom-radio [type=radio]:checked+label {
      border: 1px solid $brand-primary;
      background-color: $bg-light;
    }

  // Payment module image/text position swap
    label {
      > span {
        order: 1;
      }
      img {
        order: 0;
        margin-right: $spacer / 2;
      }
    }
  }
}

.js-additional-information {
  background-color: $bg-light;
  border: $border;
  border-top: none;
  padding: 15px;
  margin-top: -$spacer / 2;
  margin-bottom: $spacer / 2;
  display: none !important;
}

#conditions-to-approve {
  background-color: $bg-light;
  padding: 10px;
  line-height: normal;

  label {
    margin: 0;
  }
}