#cmsblockcontainer {
  background: url('#{$assets_dir}img/footer_bg1.png') bottom right no-repeat #fff;

  @include screen-md-max {
    background: #fff;
  }
}

#angelalangford_cmsblock {
  color: $font-color;

  .cmsblock {
    @include screen-md-min {
      border-right: 1px solid $brand-primary;
      margin-bottom: $spacer;

      @include screen-lg-min {
        &:nth-child(4n+4) {
          border: none;
        }
      }

      @include screen-md {
        &:nth-child(2n+2) {
          border: none;
        }
      }
    }

    @include screen-sm-max {
      &:nth-child(odd) {
        padding-right: 7.5px;
      }

      &:nth-child(even) {
        padding-left: 7.5px;
      }
    }

    @include small-mobile {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 15px !important;
      padding-right: 15px !important;

      .imagecontent {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
      }

      .textcontent {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
      }
    }

    .inner {
      @include screen-sm-max {
        border: 1px solid #666;
        padding: 10px;
        margin-bottom: 15px;
        background: #fff3f6;
      }
    }

    .textcontent,
    .imagecontent {
      padding: 0 15px;
      text-align: center;
    }

    .imagecontent {
      @include small-mobile {
        align-self: center;
      }
    }

    .textcontent {
      p {
        line-height: normal;
        margin-bottom: 0;
      }

      @include small-mobile {
        text-align: left;
      }
    }

    p {
      line-height: $font-line-height;
      @include screen-sm-max {
        margin-top: 0;
        line-height: 18px;
      }
    }

    em {
      width: 90px;
      height: 70px;
      display: block;
      margin: 0 auto;
      background-position: top right;
      background-repeat: no-repeat;

      @include screen-sm-max {
        max-height: 50px;
        background-position: center top;
      }

      @include small-mobile {
        width: 65px;
        max-height: 70px;
      }

      &.delivery {
        background-image: url('#{$assets_dir}img/delivery.svg');
        @include screen-sm-max {
          background-image: url('#{$assets_dir}img/delivery-pink.svg');
        }

        &:hover {
          background-image: url('#{$assets_dir}img/delivery-pink.svg');
        }
      }

      &.cruelty {
        background-image: url('#{$assets_dir}img/cruelty.svg');
        @include screen-sm-max {
          background-image: url('#{$assets_dir}img/cruelty-pink.svg');
        }

        &:hover {
          background-image: url('#{$assets_dir}img/cruelty-pink.svg');
        }
      }

      &.customer-care {
        background-image: url('#{$assets_dir}img/customer-care.svg');
        @include screen-sm-max {
          background-image: url('#{$assets_dir}img/customer-care-pink.svg');
        }

        &:hover {
          background-image: url('#{$assets_dir}img/customer-care-pink.svg');
        }
      }

      &.feedback {
        background-image: url('#{$assets_dir}img/feedback.svg');
        @include screen-sm-max {
          background-image: url('#{$assets_dir}img/feedback-pink.svg');
        }

        &:hover {
          background-image: url('#{$assets_dir}img/feedback-pink.svg');
        }
      }

      &.payments {
        background-image: url('#{$assets_dir}img/payments.svg');
        @include screen-sm-max {
          background-image: url('#{$assets_dir}img/payments-pink.svg');
        }

        &:hover {
          background-image: url('#{$assets_dir}img/payments-pink.svg');
        }
      }

      &.returns {
        background-image: url('#{$assets_dir}img/returns.svg');
        @include screen-sm-max {
          background-image: url('#{$assets_dir}img/returns-pink.svg');
        }

        &:hover {
          background-image: url('#{$assets_dir}img/returns-pink.svg');
        }
      }

      &.gift-wrap {
        background-image: url('#{$assets_dir}img/gift-wrap.svg');
        @include screen-sm-max {
          background-image: url('#{$assets_dir}img/gift-wrap-pink.svg');
        }

        &:hover {
          background-image: url('#{$assets_dir}img/gift-wrap-pink.svg');
        }
      }

      &.britain {
        background-image: url('#{$assets_dir}img/britain.svg');
        @include screen-sm-max {
          background-image: url('#{$assets_dir}img/britain-pink.svg');
        }

        &:hover {
          background-image: url('#{$assets_dir}img/britain-pink.svg');
        }
      }
    }

    h4 {
      text-transform: uppercase;
      margin: 20px 0 10px 0;

      @include screen-sm-max {
        margin: 10px 0 5px;
        font-size: $h5-font-size;
      }

      @include small-mobile {
        margin: 5px 0;
      }

      a {
        color: #666;
        display: block;

        &:hover {
          color: $brand-primary;
          text-decoration: none;
        }
      }
    }
  }
}