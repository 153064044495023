#custom-text {
  margin-bottom:$spacer;

  h2 {
    color:#666;
  }
  &:after {
    display: block;
    content: " ";
    background: url('#{$assets_dir}img/editorial.png') no-repeat;
    width: 112px;
    height: 127px;
    margin: 0 auto;
  }
}