/*
	Global
*/

#gsr {
  .clear {
    clear: both;
  }

  .text-size-07 {
    font-size: 0.7em;
  }

  .text-size-1 {
    font-size: 1em;
  }

  .text-size-9 {
    font-size: 9pt;
  }

  .left {
    float: left;
  }

  .inline, .inline-block {
    display: inline !important;
  }

  .width-auto {
    width: auto;
  }

  .width-100 {
    width: 100%;
  }

  .padding-left5 {
    padding-left: 5px !important;
  }

  .padding-left10 {
    padding-left: 10px !important;
  }

  .inline-block {
    display: inline !important;
  }

  .text-center {
    text-align: center !important;
  }
}

.gsr-text-center {
  text-align: center !important;
}

#gsr {
  .nowrap {
    white-space: nowrap;
  }

  .hide {
    display: none;
  }

  .clr_0, .clr_1, .clr_5, .clr_10, .clr_15, .clr_20, .clr_30, .clr_50, .clr_80, .clr_100 {
    background: none;
    clear: both !important;
    float: none !important;
    position: relative;
    padding: 0 !important;
    margin: 0 !important;
    display: block !important;
  }
}

.gsr-clr_0, .gsr-clr_1, .gsr-clr_5, .gsr-clr_10, .gsr-clr_15, .gsr-clr_20, .gsr-clr_30, .gsr-clr_50, .gsr-clr_80, .gsr-clr_100 {
  background: none;
  clear: both !important;
  float: none !important;
  position: relative;
  padding: 0 !important;
  margin: 0 !important;
  display: block !important;
}

#gsr .clr_hr {
  background: none;
  clear: both !important;
  float: none !important;
  position: relative;
  padding: 0 !important;
  margin: 0 !important;
  display: block !important;
  height: 0 !important;
}

.gsr-clr_0 {
  height: 0 !important;
}

#gsr {
  .clr_0 {
    height: 0 !important;
  }

  .clr_1 {
    height: 1px !important;
  }
}

.gsr-clr_0 {
  height: 1px !important;
}

#gsr .clr_5, .gsr-clr_5 {
  height: 5px !important;
}

#gsr .clr_10, .gsr-clr_10 {
  height: 10px !important;
}

#gsr .clr_15, .gsr-clr_15 {
  height: 15px !important;
}

#gsr .clr_20, .gsr-clr_20 {
  height: 20px !important;
}

#gsr .clr_30, .gsr-clr_30 {
  height: 30px !important;
}

#gsr .clr_40, .gsr-clr_40 {
  height: 40px !important;
}

#gsr .clr_50, .gsr-clr_50 {
  height: 50px !important;
}

#gsr .clr_80, .gsr-clr_80 {
  height: 80px !important;
}

#gsr .clr_100, .gsr-clr_100 {
  height: 100px !important;
}

#gsr {
  .clr_hr {
    border-bottom: 1px solid #CCC !important;
  }

  div.radio span {
    display: inline !important;
  }

  .size14 {
    font-size: 14px !important;
  }

  .green {
    color: #4cbb6c !important;
  }

  .red {
    color: #a94442 !important;
  }

  .cbk-pointer {
    cursor: pointer !important;
  }
}

.gsr-col-lg-12 {
  width: 100% !important;
}

/*
	END - Global
*/

/*
	product-review-block.tpl
*/

#gsr {
  &.productAction .block-review, &.productBottom .block-review, &.productReassurance .block-review {
    background-color: #FFFFFF;
  }

  &.productAction .block-review-item, &.productBottom .block-review-item, &.productReassurance .block-review-item {
    //padding-top: 1rem;
    //padding-right: 1.5rem;
    //padding-bottom: 1rem;
    //padding-left: 1.5rem;
  }

  &.productAction .block-review-item .title, &.productBottom .block-review-item .title, &.productReassurance .block-review-item .title {
    font-weight: 700;
    font-size: 14pt;

    /*color: #2fb5d2;*/
    color: #414141;
    border-top-color: initial;
    border-top-style: none;
    border-top-width: initial;
    border-right-color: initial;
    border-right-style: none;
    border-right-width: initial;
    border-bottom-color: rgb(47, 181, 210);
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-left-color: initial;
    border-left-style: none;
    border-left-width: initial;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: rgb(235, 235, 235);
    display: block;
    padding-right: 1em;
    padding-bottom: 0.5em;
  }

  .block-review-item a {
    text-decoration: none !important;
    cursor: pointer;
  }

  &.productAction .block-review-item .display-distribution, &.productBottom .block-review-item .display-distribution, &.productReassurance .block-review-item .display-distribution {
    border: solid 1px #EBEBEB;
    padding: 2px 0 0 8px;
    width: 125px;
  }

  #bt_btn-review-form {
    margin-top: 15px;
  }

  .review-title {
    margin-bottom: 10px;
  }
}

div.modal-footer #gsr {
  .block-review {
    text-align: left !important;
  }

  #bt_btn-review-list, #bt_btn-review-form {
    display: none !important;
  }
}

/*
	END - product-review-block.tpl
*/

/*
	product-review-list.tpl
*/

#gsr {
  &.list-review {
    background-color: $white;
    display: block;
  }

  &.list-review-tabs17 {
    background-color: $white;
    display: block;

    .inline {
      display: inline !important;
    }
  }

  .reviews-wrapper {
    max-height: 600px;
    overflow: scroll;
  }

  &.list-review {
    //padding-top: 1rem;
    //padding-right: 1.5rem;
    //padding-bottom: 1rem;
    //padding-left: 1.5rem;
  }

  .review-line {
    border-bottom: $border;
    //padding-bottom: 15px;
  }

  &.list-review .border-left, &.list-review-tabs17 .border-left {
    border-left: 5px solid #DFDCDC !important;
    padding-left: 10px !important;
  }

  #comment-form .title, .review-account .title, &.all-review .title, &.list-review .title {
    font-weight: 700;
    font-size: 14pt;

    /*color: #2fb5d2;*/
    color: #414141;
    border-top-color: initial;
    border-top-style: none;
    border-top-width: initial;
    border-right-color: initial;
    border-right-style: none;
    border-right-width: initial;
    border-left-color: initial;
    border-left-style: none;
    border-left-width: initial;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: rgb(235, 235, 235);
    display: block;
    padding-right: 1em;
    padding-bottom: 0.5em;
  }

  .fb-no-valign > span {
    vertical-align: inherit !important;
  }

  .fb-height-24 > span {
    height: 24px !important;
  }

  &.list-review-item {
    padding-top: 1rem;
    padding-right: 1.5rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
  }

  .blockquote footer {
    font-size: 12px;
  }

  blockquote {
    margin-left: 30px;

    p {
      font-size: 15px;
    }
  }
}

/*#gsr .list-review {margin-bottom: 10px !important; display: inline;}*/
/*#gsr .list-review strong {color: #333; font-weight: normal; font-size: 16px;}*/
/*#gsr .list-review a {font-size: 10pt;font-weight: 600;}*/

/*
	END - product-review-list.tpl
*/

/*
	hook/review-page-list.tpl (stars and rating returned into ProductListReview hook)
*/

.gsr-review-list {
  label:before {
    font-size: inherit !important;
  }

  &.bg-white, .bg-white {
    background-color: white;
    line-height: 25px !important;
  }
}

.gsr-review-count-padding {
  padding: 3px !important;
}

.gsr-review-count-padding-1611 {
  vertical-align: top !important;
}

.gsr-review-count-rating {
  vertical-align: middle;
  color: #333;
}

.gsr-review-stars-1611 div.radio span {
  display: inline !important;
}

.gsr-review-count-rating-1611 {
  vertical-align: top !important;
  color: #333;
  padding: 0 !important;
}

.gsr-no-underline {
  text-decoration: no-underline !important;
}

.gsr-side-text-8 {
  font-size: 8px !important;
  xfloat: left !important;
  xpadding-left: 10px !important;
}

.gsr-side-text-9 {
  font-size: 9px !important;
  xfloat: left !important;
  xpadding-left: 10px !important;
}

.gsr-side-text-10 {
  font-size: 10px !important;
  xfloat: left !important;
  xpadding-left: 10px !important;
}

.gsr-side-text-11 {
  font-size: 11px !important;
  xfloat: left !important;
  xpadding-left: 10px !important;
}

.gsr-side-text-12 {
  font-size: 12px !important;
  xfloat: left !important;
  xpadding-left: 10px !important;
}

.gsr-side-text-13 {
  font-size: 13px !important;
  xfloat: left !important;
  xpadding-left: 10px !important;
}

.gsr-side-text-14 {
  font-size: 14px !important;
  xfloat: left !important;
  xpadding-left: 10px !important;
}

.gsr-side-text-15 {
  font-size: 15px !important;
  xfloat: left !important;
  xpadding-left: 10px !important;
}

.gsr-side-text-16 {
  font-size: 16px !important;
  xfloat: left !important;
  xpadding-left: 10px !important;
}

.gsr-side-text-17 {
  font-size: 17px !important;
  xfloat: left !important;
  xpadding-left: 10px !important;
}

.gsr-side-text-18 {
  font-size: 18px !important;
  xfloat: left !important;
  xpadding-left: 10px !important;
}

#gsr {
  .review-line-comment {
    margin-bottom: 10px !important;
    display: inline;

    strong {
      color: #333;
      font-weight: normal;
      font-size: 16px;
    }

    a {
      font-size: 10pt;
      font-weight: 600;
    }

    p {
      padding-left: 15px !important;

      &.border-left {
        border-left: 5px solid #DFDCDC !important;
        padding-left: 10px !important;
      }
    }
  }

  .review-line-list {
    margin-bottom: 10px !important;
    display: inline;
  }

  #comment-form {
    color: #333;
    text-align: left;
    padding-right: 15px !important;

    label {
      text-align: left !important;
    }

    .border-bottom {
      border-bottom-width: 3px;
      border-bottom-style: solid;
      border-bottom-color: rgb(235, 235, 235);
    }

    .inline {
      display: inline-block;
    }

    .padding-20 {
      padding: 0 20px 20px 20px;
    }

    .margin-top {
      margin-top: 9px;
    }

    .margin-left {
      margin-left: 10px;
    }

    .stars-right {
      float: right !important;
      margin-right: 5px;
      display: inline !important;
      margin-top: 0px;
    }

    .review {
      cursor: pointer;
      margin-bottom: 15px;
      display: block;
    }

    .review-report {
      float: right !important;
      margin-bottom: 10px !important;
      cursor: pointer !important;
    }

    .review-title {
      width: 300px;
    }

    .review-comment {
      width: 300px;
      height: 200px;
    }

    .product {
      a {
        display: inline-block;
      }

      img {
        float: left;
        margin-right: 20px;
        border: 1px solid #ccc;
      }

      .description {
        margin-top: 15px;
        line-height: 18px;
        color: #666;

        a {
          width: 100% !important;

          img {
            float: left;
            margin-right: 20px;
          }
        }
      }
    }

    label {
      display: block;
      margin: 12px 0 4px 0;
      font-weight: bold;
      font-size: 12px;
    }

    input {
      padding: 0 5px;
      height: 28px;
      width: 100% !important;
      min-width: 100% !important;
      border: 1px solid #ccc;
      background: #fff;
    }

    img.left {
      float: left;
      margin-right: 20px;
    }

    textarea {
      padding: 0 5px;
      height: 200px;
      width: 100% !important;
      min-width: 100% !important;
      border: 1px solid #ccc;
      background: #fff;
    }

    div.rewards-info {
      padding: 5px 10px !important;
      background-color: #f6f6f7;
      border: 1px solid #D0D3D8;
      margin-bottom: 10px;

      p {
        margin: 0 !important;
        padding: 5px 0 !important;
        vertical-align: middle;

        span {
          vertical-align: middle;

          img {
            vertical-align: middle;
          }
        }
      }
    }

    .footer {
      margin-top: 20px;
      font-size: 12px;
    }

    .content {
      padding-bottom: 10px;
      font-weight: bold;
      font-size: 12px;
    }

    .required {
      font-size: 12px;
      color: red;
    }

    .size12 {
      margin-right: 10px;
      font-size: 12px !important;
    }

    .help-block {
      color: #b7b7b7 !important;
    }

    .has-error {
      input {
        &[type="text"], &[type="search"], &[type="password"] {
          background-color: #f9cce1;
          -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        }
      }

      textarea, select {
        background-color: #f9cce1;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      }
    }
  }

  .last-reviews-top, .last-reviews-home, .last-reviews-bottom, .last-reviews-colLeft, .last-reviews-colRight {
    background: #fff;
    border-radius: 2px;
  }

  .last-reviews-top, .last-reviews-home, .last-reviews-bottom {
    margin-bottom: 1rem;
    padding-top: 1.125rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    padding-bottom: 3.125rem;
  }

  .last-reviews-colLeft, .last-reviews-colRight {
    margin-bottom: 1rem;
    padding-top: 1.05rem;
    padding-left: 1.05rem;
    padding-right: 1.05rem;
    padding-bottom: 3.125rem;
  }

  .last-reviews-top .h2, .last-reviews-home .h2, .last-reviews-bottom .h2, .last-reviews-colLeft .h2, .last-reviews-colRight .h2 {
    padding-bottom: 0.5em;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: rgb(235, 235, 235);
  }

  .last-reviews-bottom div.align-right {
    float: right;
    margin-right: 5px;
  }
}

/*
	END - hook/review-page-list.tpl (stars and rating returned into ProductListReview hook
*/

/*
	comment form (hook/product-review-form.tpl / front/review-form.tpl)
*/

/* Start responsive modal */
@media only screen and (max-width: 1020px) {
  #fancybox-wrap {
    width: 70% !important;
    margin: 3% 15% !important;
    left: 0 !important;

    #fancybox-content, #fancybox-img {
      height: 100% !important;
      width: auto !important;
      text-align: center;
      max-width: 100% !important;
    }

    #fancybox-content {
      width: 98% !important;
      padding: 1% !important;
      border: none !important;
    }
  }

  #gsr #comment-form .product img {
    width: 45%;
    margin-right: 5%;
  }
}

@media only screen and (max-width: 750px) {
  #gsr #comment-form .product {
    img {
      width: 100%;
      margin: 0;
      margin-bottom: 10px;
    }

    .description {
      margin: 0;
    }

    .margin-15 {
      margin: 15px !important;
    }
  }
}

/* Stop responsive modal */
/*
	END - comment form (hook/product-review-form.tpl / front/review-form.tpl)
*/

/*
	last-reviews-block.tpl
*/

.last-reviews-top div.align-right, .last-reviews-home div.align-right, .last-reviews-colLeft div.align-right, .last-reviews-colRight div.align-right {
  float: right;
  margin-right: 5px;
}

#gsr {
  .last-reviews-bottom div.review-line, .last-reviews-top div.review-line {
    padding-left: 15px;
    padding-right: 10px;
  }

  .last-reviews-bottom {
    .review-name {
      color: inherit;
    }

    .review-line-name strong {
      color: #777;
    }

    .title-block {
      background: none;
      border: none;
      color: #FFF !important;
      text-transform: none;
      padding: 0;
      margin: 0;
      margin-bottom: 15px;

      a {
        background: none;
        border: none;
        color: #FFF !important;
        text-transform: none;
        padding: 0;
        margin: 0;
        margin-bottom: 15px;
      }
    }

    div.align-right {
      float: right;
      margin-right: 5px;
    }

    .btn.btn-default.button.button-small {
      color: #555454 !important;
    }
  }

  table th.min-120 {
    min-width: 120px;
  }

  .icon-ok-sign {
    font-size: 20px !important;
    color: #3C763D;
  }

  .icon-remove-sign, .icon-time {
    font-size: 20px;
    color: #A94442;
  }

  .homeslider-container {
    .homeslider-description {
      padding: 20px;

      h3 {
        background: #FFF;
      }

      p {
        width: 100%;
      }
    }

    .wrap-ao-rating-block {
      position: absolute;
      top: 50px;
      left: 0;
      width: 100%;
      text-align: center;

      .ao-rating-block {
        display: inline-block;
        padding: 20px;
        background-color: rgb(255, 255, 255);
        background-color: rgba(255, 255, 255, 0.9);

        .btn {
          display: inline-block;
          display: inline-block;
        }

        .star-rating-control {
          display: inline-block;
        }
      }
    }
  }

  #homepage-slider .bx-controls .bx-controls-direction {
    a {
      background: none;
      border: none;

      &:hover:before {
        background: none;
        border: none;
      }
    }

    .bx-prev:after, .bx-next:after {
      color: #2fb5d2 !important;
      font-size: 30px !important;
    }

    a {
      margin-top: 50px !important;
    }
  }
}

/*
	END - last-reviews-block.tpl
*/

/*
	My account > my reviews page (front/customer-account.tpl)
*/

/* Slider block */

/*
	END - My account > my reviews page (front/customer-account.tpl)
*/



/****** jQuery.Rating Plugin CSS - http://www.fyneworks.com/jquery/star-rating/ ******/

div {
  &.rating-cancel, &.star-rating {
    float: left;
    width: 17px;
    height: 15px;
    text-indent: -999em;
    cursor: pointer;
    display: block;
    background: transparent;
    overflow: hidden;
  }

  &.rating-cancel a, &.star-rating a {
    display: block;
    width: 16px;
    height: 100%;
    background-position: 0 0px;
    border: 0;
  }

  &.star-rating-on a {
    background-position: 0 -32px !important;
  }

  &.star-rating-hover a {
    background-position: 0 -16px !important;
  }

  &.star-rating-readonly a {
    cursor: default !important;
  }

  &.star-rating {
    background: transparent !important;
    overflow: hidden !important;
  }
}

/****** END jQuery.Rating Plugin CSS ******/

/****** Style Star Rating Widget CSS Only - BEGIN *****/


//.rating-star-green,
//.rating-star-blue,
.rating-star-yellow {
  margin-right: $spacer / 2;
  //border: none;
  //display:  !important;
}

//.gsr-stars-padding-left-1 {
//  padding-left: 1px !important;
//}
//
//.gsr-stars-padding-left-2 {
//  padding-left: 2px !important;
//}
//
//.gsr-stars-padding-left-3 {
//  padding-left: 3px !important;
//}
//
//.gsr-stars-padding-left-4 {
//  padding-left: 4px !important;
//}
//
//.gsr-stars-padding-left-5 {
//  padding-left: 5px !important;
//}
//
//.gsr-stars-padding-left-6 {
//  padding-left: 6px !important;
//}
//
//.gsr-stars-padding-left-7 {
//  padding-left: 7px !important;
//}
//
//.gsr-stars-padding-left-8 {
//  padding-left: 8px !important;
//}
//
//.gsr-stars-padding-left-9 {
//  padding-left: 9px !important;
//}
//
//.gsr-stars-padding-left-10 {
//  padding-left: 10px !important;
//}
//
//.gsr-stars-padding-left-11 {
//  padding-left: 11px !important;
//}
//
//.gsr-stars-padding-left-12 {
//  padding-left: 12px !important;
//}
//
//.gsr-stars-padding-left-13 {
//  padding-left: 13px !important;
//}
//
//.gsr-stars-padding-left-14 {
//  padding-left: 14px !important;
//}
//
//.gsr-stars-padding-left-15 {
//  padding-left: 15px !important;
//}
//
//.gsr-stars-padding-left-16 {
//  padding-left: 16px !important;
//}
//
//.gsr-stars-padding-left-17 {
//  padding-left: 17px !important;
//}
//
//.gsr-stars-padding-left-18 {
//  padding-left: 18px !important;
//}
//
//.gsr-stars-padding-left-19 {
//  padding-left: 19px !important;
//}
//
//.gsr-stars-padding-left-20 {
//  padding-left: 20px !important;
//}

/* the default stars settings - full star */

//.rating-star-green > input,
//.rating-star-blue > input,
.rating-star-yellow > input {
  display: none;
}

//.rating-star-green > label:before,
//.rating-star-blue > label:before,
//.rating-star-green > label,
//.rating-star-blue > label,
.rating-star-yellow > label,
.rating-star-yellow > label:before {
  font-size: 1.35em !important;
  font-family: FontAwesome;
  display: inline-block !important;
  content: "\f005";
  width: initial !important;
}

/* the default stars settings - half star */

//.rating-star-green > .half:before,
//.rating-star-blue > .half:before,
.rating-star-yellow > .half:before {
  font-size: 1.15em !important;
  font-family: FontAwesome;
  display: inline-block !important;
  content: "\f089";
  position: relative;
  top: 0px;
  left: 8px;
}

/* the star rating on the product page - block */

.product-rating-wrapper {
  pointer-events: none;
}

//.rating-star-green > label.product-block:before,
//.rating-star-blue > label.product-block:before,
//.rating-star-green > label.product-block,
//.rating-star-blue > label.product-block,
.rating-star-yellow > label.product-block:before,
.rating-star-yellow > label.product-block {
  font-size: 1.20em !important;
}

//.rating-star-green > label.product-block-half:before,
//.rating-star-blue > label.product-block-half:before,
//.rating-star-green > label.product-block-half,
//.rating-star-blue > label.product-block-half,
.rating-star-yellow > label.product-block-half,
.rating-star-yellow > label.product-block-half:before {
  margin-left: -3px !important;
  font-size: 1.10em !important;
}


//.rating-star-green > .half.product-block-half:before,
//.rating-star-blue > .half.product-block-half:before,
.rating-star-yellow > .half.product-block-half:before {
  left: 7px;
}

/* the star rating on the product page - distribution block */

//.rating-star-green > label.distrib-front:before,
//.rating-star-blue > label.distrib-front:before,
//.rating-star-green > label.distrib-front,
//.rating-star-blue > label.distrib-front,
.rating-star-yellow > label.distrib-front,
.rating-star-yellow > label.distrib-front:before {
  margin: 1px !important;
  font-size: 1em !important;
}

/* the star rating on the product page - product list */

//.rating-star-green > label.product-tab:before,
//.rating-star-blue > label.product-tab:before,
//.rating-star-green > label.product-tab,
//.rating-star-blue > label.product-tab,
.rating-star-yellow > label.product-tab,
.rating-star-yellow > label.product-tab:before {
  margin-left: 1px !important;
  font-size: 1.1em !important;
}

.last-reviews-colLeft {
  //.rating-star-green > label.product-tab:before,
  //.rating-star-blue > label.product-tab:before,
  //.rating-star-green > label.product-tab,
  //.rating-star-blue > label.product-tab,
  .rating-star-yellow > label.product-tab,
  .rating-star-yellow > label.product-tab:before {
    margin-left: 1px !important;
    font-size: 1em !important;
  }
}

.last-reviews-colRight {
  //.rating-star-green > label.product-tab:before,
  //.rating-star-blue > label.product-tab:before,
  //.rating-star-green > label.product-tab,
  //.rating-star-blue > label.product-tab,
  .rating-star-yellow > label.product-tab,
  .rating-star-yellow > label.product-tab:before {
    margin-left: 1px !important;
    font-size: 1em !important;
  }
}

/* the star rating on the product list page and on each page where a review badge is displayed */

//label {
//  &.rating-star-size-090 {
//    font-size: 0.90em !important;
//  }
//
//  &.rating-star-size-091 {
//    font-size: 0.91em !important;
//  }
//
//  &.rating-star-size-092 {
//    font-size: 0.92em !important;
//  }
//
//  &.rating-star-size-093 {
//    font-size: 0.93em !important;
//  }
//
//  &.rating-star-size-094 {
//    font-size: 0.94em !important;
//  }
//
//  &.rating-star-size-095 {
//    font-size: 0.95em !important;
//  }
//
//  &.rating-star-size-096 {
//    font-size: 0.96em !important;
//  }
//
//  &.rating-star-size-097 {
//    font-size: 0.97em !important;
//  }
//
//  &.rating-star-size-098 {
//    font-size: 0.98em !important;
//  }
//
//  &.rating-star-size-099 {
//    font-size: 0.99em !important;
//  }
//
//  &.rating-star-size-100 {
//    font-size: 1.00em !important;
//  }
//
//  &.rating-star-size-101 {
//    font-size: 1.01em !important;
//  }
//
//  &.rating-star-size-102 {
//    font-size: 1.02em !important;
//  }
//
//  &.rating-star-size-103 {
//    font-size: 1.03em !important;
//  }
//
//  &.rating-star-size-104 {
//    font-size: 1.04em !important;
//  }
//
//  &.rating-star-size-105 {
//    font-size: 1.05em !important;
//  }
//
//  &.rating-star-size-106 {
//    font-size: 1.06em !important;
//  }
//
//  &.rating-star-size-107 {
//    font-size: 1.07em !important;
//  }
//
//  &.rating-star-size-108 {
//    font-size: 1.08em !important;
//  }
//
//  &.rating-star-size-109 {
//    font-size: 1.09em !important;
//  }
//
//  &.rating-star-size-110 {
//    font-size: 1.10em !important;
//  }
//
//  &.rating-star-size-111 {
//    font-size: 1.11em !important;
//  }
//
//  &.rating-star-size-112 {
//    font-size: 1.12em !important;
//  }
//
//  &.rating-star-size-113 {
//    font-size: 1.13em !important;
//  }
//
//  &.rating-star-size-114 {
//    font-size: 1.14em !important;
//  }
//
//  &.rating-star-size-115 {
//    font-size: 1.15em !important;
//  }
//
//  &.rating-star-size-116 {
//    font-size: 1.16em !important;
//  }
//
//  &.rating-star-size-117 {
//    font-size: 1.17em !important;
//  }
//
//  &.rating-star-size-118 {
//    font-size: 1.18em !important;
//  }
//
//  &.rating-star-size-119 {
//    font-size: 1.19em !important;
//  }
//
//  &.rating-star-size-120 {
//    font-size: 1.20em !important;
//  }
//}

/*.rating-star-yellow > label.list-front-half.rating-star-size-115:before,*/
/*.rating-star-green > label.list-front-half.rating-star-size-115:before,*/
/*.rating-star-blue > label.list-front-half.rating-star-size-115:before {left: 7px !important;}*/

//.rating-star-green > label.badge-front:before,
//.rating-star-blue > label.badge-front:before,
//.rating-star-green > label.badge-front,
//.rating-star-blue > label.badge-front,
//.rating-star-green > label.badge-front-half:before,
//.rating-star-blue > label.badge-front-half:before,
//.rating-star-green > label.badge-front-half,
//.rating-star-blue > label.badge-front-half,
//.rating-star-green > label.list-front:before,
//.rating-star-blue > label.list-front:before,
//.rating-star-green > label.list-front,
//.rating-star-blue > label.list-front,
//.rating-star-green > label.list-front-half:before,
//.rating-star-blue > label.list-front-half:before,
//.rating-star-green > label.list-front-half,
//.rating-star-blue > label.list-front-half,
.rating-star-yellow > label.badge-front:before,
.rating-star-yellow > label.badge-front,
.rating-star-yellow > label.badge-front-half:before,
.rating-star-yellow > label.badge-front-half,
.rating-star-yellow > label.list-front:before,
.rating-star-yellow > label.list-front,
.rating-star-yellow > label.list-front-half:before,
.rating-star-yellow > label.list-front-half {
  font-size: 1.15em !important;
}

//.rating-star-green > label.badge-front:before,
//.rating-star-blue > label.badge-front:before,
//.rating-star-green > label.badge-front,
//.rating-star-blue > label.badge-front,
.rating-star-yellow > label.badge-front,
.rating-star-yellow > label.badge-front:before {
  margin-left: 1px !important;
}

//.rating-star-green > .badge-front-half:before,
//.rating-star-blue > .badge-front-half:before,
.rating-star-yellow > .badge-front-half:before {
  margin-left: -2px;
  margin-right: -1px;
  left: 5px;
}

//.rating-star-green > .list-front-half:before,
//.rating-star-blue > .list-front-half:before,
.rating-star-yellow > .list-front-half:before {
  margin-left: -3px;
  margin-right: -3px;
  left: 7px;
}

//.rating-star-green > label,
//.rating-star-blue > label,
.rating-star-yellow > label {
  color: #C4C4C4;
}

/* highlight with the good color */

.rating-star-yellow > {
  input:checked + label, label.checked {
    color: $brand-primary !important;
  }
}

//.rating-star-green > {
//  input:checked + label, label.checked {
//    color: #47a020 !important;
//  }
//}
//
//.rating-star-blue > {
//  input:checked + label, label.checked {
//    color: #1a67ac !important;
//  }
//}

/****** Style Star Rating Widget CSS Only - END *****/