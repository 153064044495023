// 404 Error page

.page_error {
  text-align: center;

  .error_number {
    display: block;
    font-family: $font-family-jenna;
    font-size: 10rem;
    line-height: 3rem;
    margin-top: 60px;
    margin-bottom: 40px;
  }

  h1 {
    margin-top: $spacer;
  }
}

#search_widget_wrapper {
  margin-top: $spacer / 2;
  padding-top: $spacer / 2;
  border-top: $border-width dashed $border-color;

  #search_widget {
    margin: $spacer auto;
    max-width: 400px;
    display: block !important;

    @include screen-sm-max {
      margin: ($spacer / 2) auto;
    }

    form {
      position: relative !important;
        transform: none;
        top: initial;
        left: initial;
    }
  }
}

