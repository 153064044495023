.cart-summary {
  .cart-summary__inner {
    border: $border-width solid $border-color;
    padding: $spacer / 2;
    background-color: $white;
    border-radius: $border-radius;

    @include screen-md-max {
      margin-bottom: $spacer / 2;
    }

    table {
      margin-bottom: $spacer / 2;
    }
  }
}

#cart {
  .page {
    .product-name {
      a {
        color: $brand-primary;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .remove-from-cart {
      color: $brand-primary;
    }
  }
}

// Cart Vouchers
.cart-voucher {
  padding: 30px 0;
  border-bottom: $border;

  #cart & {
    border-top: $border;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  h5 {
    margin: 0 0 10px;
  }

  &-applied {
    margin: 0 0 ($spacer / 2);
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 10px;
      background-color: $white;

      &:last-of-type {
        margin-bottom: 15px;
      }

      p {
        position: relative;
        border: $border-width solid darken($notification-success, 40%);
        background-color: $notification-success;
        line-height: 1.4em;
        margin-bottom: 0;
        padding: 10px 35px 10px 10px;
        font-size: 0.8rem;
        color: #3c763d;
      }

      a {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        color: #3c763d;
      }
    }
  }

  input {
    border-right: none;
  }

  .cart-vouchers-available {
    margin-top: 20px;

    li {
      background-color: $bg-light;
      border: $border;
      margin-top: 15px;
      font-size: 0.8rem;
      line-height: 1.4em;

      p {
        padding: 5px;
        margin-bottom: 0;
      }

      a {
        color: $white;

        &:hover {
          color: $white;
        }
      }
    }
  }
}