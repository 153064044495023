.js-address-form {
  #invoice-addresses,
  #delivery-addresses {
    article {
      background-color: $body-bg;
      border-radius: $border-radius;
      border: $border-color solid $border-width;
      font-size: initial;
      margin-bottom: $spacer / 2;
      width: 100%;
      display: block;

      &:hover {
        border-color: $border-color;
      }

      // &.selected {
      //   border-color: darken($border-color, 10%);
      // }

      .radio-block {
        padding: $spacer / 2;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .custom-radio {
          max-width: 75%;

          @include screen-xs {
            max-width: 85%;
            margin-right: 0;
          }
        }

        label {
          margin: 0;
        }

        input:checked + .address-item {
          border-color: darken($border-color, 10%);
        }

        .interactive {
          //line-height: 0;

          a {
            display: inline-block;
            vertical-align: top;

            i {
              color: darken($border-color, 10%);

              &:hover {
                color: $brand-primary;
              }
            }

            @include screen-sm-min {
              margin-left: $spacer / 4;
            }

            @include screen-xs {
              &:first-of-type {
                margin-bottom: $spacer / 2;
              }
            }
          }

          @include screen-xs {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
          }
        }
      }

      @include screen-sm-max {
        flex: 100%;
      }
    }
  }
}

.delivery-address-wrapper {
  margin-bottom: $spacer;
}