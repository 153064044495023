#blockcart-wrapper {
  margin-top: 20px;
  position: relative;

  @include screen-md-max {
    padding-right: 10px;
  }

  @include screen-xs {
    margin-top: 15px;
  }

  @media (max-width: 420px) {
    margin-top: 10px;
  }

  // Cart info in header
  .blockcart {
    .headercart {
      text-align: right;

      //@include screen-md-max {
      //  padding-right: 10px;
      //}

      a {
        color: $brand-primary;
        background-color: $white;

        &:hover {
          text-decoration: $link-decoration;
        }

        img {
          @include screen-sm-max {
            max-height: 30px;
          }
          @include screen-xs {
            max-height: 25px;
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }

      .cartsection {
        display: block;
        position: relative;

        .cart-icon {
          width: 30px;
          height: auto;
        }
      }

      .viewbag {
        font-family: $font-family-jenna;
        font-size: $font-size * 2.5;
        font-style: italic;
        font-weight: $font-weight;
        padding-right: 15px;
      }

      .products_count {
        border-radius: 50%;
        font-weight: $font-bold;
        width: 25px;
        height: 25px;
        margin-left: 5px;
        text-align: center;
        line-height: 25px;
        font-size: 14px;
        background: $brand-primary;
        color: #fff;

        @include screen-md-max {
          width: 20px;
          height: 20px;
          font-size: 14px;
          line-height: 20px;
          position: absolute;
          top: -5px;
          right: -10px;
        }

        @include screen-xs {
          width: 15px;
          height: 15px;
          font-size: 12px;
          line-height: 15px;
        }
      }

      &:hover {
        ~ .body {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    // Cart Dropdown
    .body {
      visibility: hidden;
      opacity: 0;
      margin-top: 8px;
      position: absolute;
      z-index: $z-index3;
      border: 1px solid #ddd;
      background: #fff;
      top: 30px;
      right: 0;
      min-width: 300px;
      @include boxShadow(0, 2px, 5px, 0, rgba(0,0,0,0.3));
      transition: opacity 0.35s ease-in-out, visibility 0.35s ease-in-out;

      @include screen-md-max {
        visibility: hidden !important;
        opacity: 0 !important;
      }

      &:hover {
        visibility: visible;
        opacity: 1;

        .body {
          visibility: visible;
          opacity: 1;
        }
      }

      .inner {
        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          // Individual product item rows
          li {
            border-bottom: $border-width dashed $border-color-medium;
            margin-bottom: 10px;
            padding-bottom: 10px;

            &.cart_product {
              position: relative;
              text-align: center;

              &:last-of-type {
                border-bottom: none;
                margin-bottom: 0;
                padding-bottom: 0;
              }

              .remove-from-cart-wrapper {
                height: $font-size;
                line-height: $font-size;
                margin-bottom: 5px;
              }

              .remove-from-cart {
                display: block;

                i {
                  font-size: $font-size;
                  pointer-events: none;
                }
              }

              .product-image {
                img {
                  border: $border;
                }
              }

              .product-quantity {
                padding-right: 5px;
              }

              .product-details {
                font-size: 0.8rem;
                line-height: normal;
                padding-left: 10px;
              }

              .product-name {
                color: $brand-primary;
              }

              .product-price {
                font-size: 14px;
                line-height: normal;
              }

              .product-attributes {
                background-color: $bg-light;
                display: block;
                font-size: 0.7rem;
                line-height: normal;
                margin-top: 5px;
                padding: 3px;
              }
            }
          }
        }

        div {
          span {
            &.label {
              color: $brand-primary;
            }

            &.value {
              float: right;
            }
          }
        }

        .product_content {
          padding: 10px;
        }

        .subtotal {
          border-bottom: $border;
        }

        .checkout_button {
          padding: 15px;
          background: #f6f6f6;
          text-align: center;
        }
      }

    // Cart Dropdown Totals
      .cart-subtotals,
      .cart-total {
        font-weight: $font-bold;
        font-size: 0.8rem;
      }

      .cart-subtotals {
        margin-top: 10px;
      }
    }
  }
}