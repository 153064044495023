#authentication {
  header {
    .button {
      margin-bottom: $spacer / 2;
    }
  }

  .login-form {
    label {
      font-weight: $font-bold;
    }
  }
}

#password {
.password-form {
  label {
    display: block;
  }
}
}