.order-head {
  background-color: $white;
  border-radius: $border-radius;
  padding: $spacer / 2;
  margin-bottom: $spacer / 2;
  border: $border-width solid $border-color;

  span {
    text-align: center;
    color: $brand-primary;
    font-weight: bold;
  }
}

.order-item {
  background-color: $white;
  border-radius: $border-radius;
  margin-bottom: $spacer / 2;
  border: $border-width solid $border-color;

  &:hover {
    border-color: darken($border-color, 20%);
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  a {
    padding: $spacer / 2;
  }

  span {
    text-align: center;
  }
}
