.user-info {
  text-align: right;
  font-family: $font-family-questa;

  @include screen-md-min {
    margin-top: 10px;
  }

  a {
    @include textHover($font-color);
    font-weight: 600;
    text-transform: lowercase;

    &.account,
    &.register {
      border-right: 2px solid $brand-primary;
      padding-right: 10px;
    }

    &.login,
    &.logout {
      padding-left: 10px;
    }

    &:hover {
      text-decoration:$link-decoration;
    }

    .customer-name {
      //text-transform: capitalize;
    }
  }
}