.form-group {
  margin-bottom: $spacer / 2;
}

.form-footer {
  //text-align: right;
  margin-top: $spacer / 2;

  &.mutliple-buttons {
    @include screen-sm-min {
      display: flex;
      justify-content: space-between;
    }
  }

  @include screen-sm-max {
    > .button:first-of-type {
      margin-bottom: $spacer / 2;
    }
  }
}

.form-errors {
  display: block;
  margin: 0 0 $spacer / 2 0;
  padding: 0;
  background-color: $notification-danger;
  border: $border-width solid darken($notification-danger, 20%);
  border-radius: $border-radius;

  li {
    line-height: $font-line-height * 1.5 + $border-width * 2;
    max-height: $font-line-height * 1.5 + $border-width * 2;
    color: darken($notification-danger, 20%);
    list-style-type: none;
    padding: 0 15px;
    display: block;
  }
}

.form-control {
  font-size: $font-size;
}