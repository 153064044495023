.select-wrapper {
  position: relative;

  &:after {
    content: "\E313";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    font-feature-settings: "liga";
    font-family: "Material Icons";
    font-size: $font-size * 1.6;
    line-height: $element-height;
    pointer-events: none;
  }

  &-auto-width {
    display: inline-block;
  }
}

select {
  appearance: none;
  -webkit-appearance: none;
  border-radius: $border-radius;
  border: $border-width solid $border-color;
  display: block;
  height: $element-height;
  width: 100%;
  padding: 0 40px 0 10px;
  outline: 0;
  background-color: $white;
  cursor: pointer;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    &::-ms-value {
      color: $font-color;
      background-color: $transparent;
    }
  }
}
