// Show stripe logo on Prestashop's generic payment option label
.payment-options {
  .payment-option.stripe_official {
    .custom-radio {
      label {
        span {
          &:before {
            content: " ";
            display: inline-block;
            vertical-align: middle;
            height: 29px;
            width: 100px;
            margin-right: 20px;
            background: url("#{$assets_dir}img/checkout-payment-step-stripe-logo.png") top left no-repeat;
          }
        }
      }
    }
  }
}

// Checkout Payment step form

#stripe-card-payment {
  background-color: $bg-light;
  border: $border;
  border-top: none;
  padding: 15px 15px 0;
  margin: -15px 0 20px !important;

  .form-row {
    display: block;

    #cards-logos {
      @include screen-xs {
        text-align: center;
      }
    }
  }

  &.stripe-payment-form {
    label {
      font-weight: $font-bold;
    }

    .stripe-card-cardholdername,
    .stripe-card-cvc,
    .stripe-card-expiry {
      @include screen-sm-min {
        width: 49%;
        display: inline-block;
        vertical-align: top;
        max-width: 49%;
      }

      @include screen-xs {
        text-align: center;
        width: 100%;
        margin: 0 auto 15px;
        max-width: 100%;
      }
    }

    .stripe-card-cardholdername input,
    #stripe-card-number,
    #stripe-card-expiry,
    #stripe-card-cvc,
    #stripe-card-postalcode {
      @include screen-xs {
        margin: 0 auto 15px;
      }
    }
  }
}
