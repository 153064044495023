* {
  box-sizing: border-box;
}

*:after,
*:before {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  background-color: $body-bg;
  font-family: $font;
  font-size: $font-size;
  font-weight: $font-weight;
  color: $font-color;
  line-height: $font-line-height;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  min-width: 320px;
}

h1, .h1 {
  font-size: $h1-font-size;
  font-family: $font-family-questa;
  color: $brand-primary;

  @include screen-sm-max {
    font-size: $h2-font-size * 0.95;
  }

  @include screen-xs {
    font-size: $h1-font-size * 0.85;
  }
}

h2, .h2 {
  font-size: $h2-font-size;
  font-family: $font-family-questa;
  color: $brand-primary;

  @include screen-sm-max {
    font-size: $h2-font-size * 0.85;
  }

  @include screen-xs {
    font-size: $h2-font-size * 0.75;
  }
}

h3, .h3 {
  font-size: $h3-font-size;

  @include screen-sm-max {
    font-size: $h2-font-size * 0.9;
  }

  @include screen-xs {
    font-size: $h3-font-size * 0.85;
  }
}

h4, .h4 {
  font-size: $h4-font-size;
}

h5, .h5 {
  font-size: $h5-font-size;
}

h6, .h6 {
  font-size: $h6-font-size;
}

// Override heading font family
h1, h2, h3, h4, h5, h6 {
  text-transform: lowercase;
  line-height: 1.2em;

  &.highlight {
    font-family: $font-family-questa;
  }
}

// Title background lines
.title-line {
  display: table;
  white-space: nowrap;
  width: 95%;
  margin-left: 2.5%;

  &:before, &:after {
    border-top: $border-width solid $border-color-medium;
    content: '';
    display: table-cell;
    position: relative;
    top: 0.5em;
    width: 45%;
  }

  &:before {
    right: 1.5%;
  }

  &:after {
    left: 1.5%;
  }
}


p {
  margin: 0 0 0.7rem;
}

i {
  font-style: normal;
}

a {
  color: $link-color;
  text-decoration: $link-decoration;

  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  i {
    pointer-events: none;
  }
}

img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

.table {
  margin-bottom: initial;

  td,
  th {
    padding: 0.5em;
  }

  &-margin-bottom {
    margin-bottom: $spacer;
  }
}

.table-responsive {
  display: table;

  @include screen-sm-max {
    display: block;
    border: none;
  }
}

// Site width/containers
.container-fluid {
  max-width: 1280px;
}

.container {
  @media (min-width: 1300px) {
    max-width: 1280px;
  }

  @include screen-sm-max {
    max-width: 100%;
  }
}

.full-width-small-mobile {
  @media (max-width: 479px) {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

// Text Alignment
.text-center {
  text-align: center;
}

.text-center-small-mobile {
  @media (max-width: 479px) {
    text-align: center;
  }
}

.text-right {
  text-align: right;
}

.text-left-center {
  text-align: left;

  @include screen-sm-max {
    text-align: center;
  }
}

.text-right-center {
  text-align: right;

  @include screen-sm-max {
    text-align: center;
  }
}

.font-jenna {
  font-family: $font-family-jenna;
}

.font-questa {
  font-family: $font-family-questa;
}

.disabled {
  opacity: 0.5;
}

.theme-pink {
  color: $brand-primary
}

.theme-blue {
  color: $brand-tertiary
}

.product-name {
  font-family: $font-family-questa;
  color: $brand-primary;

  a {
    color: $brand-primary;
  }
}

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.strikethrough {
  position: relative;
  padding: 0 3px;
}

.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 1px solid;
  border-color: inherit;

  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

iframe {
  border: none;
}

// Angela Langford stars used throughout site
.star-img {
  display: inline-block;
  width: 25px;
  height: 25px;

  &-small {
    width: 20px;
    height: 20px;
  }

  &-large {
    width: 30px;
    height: 30px;
  }

  &-block {
    display: block;
  }

  &-bg-only-fill {
    background-image: url("#{$assets_dir}img/star-fill.svg");
    display: inline-block;
    vertical-align: middle;
  }

  &-bg-only-empty {
    background-image: url("#{$assets_dir}img/star-no-fill.svg");
    display: inline-block;
    vertical-align: middle;
  }
  &-bg-only-fill,
  &-bg-only-empty {
    background-repeat: no-repeat;
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}