#module-ps_emailalerts-account {
  .page__content {
    ul {
      padding: 0;
      list-style-type: none;

      li {
        &.mail-alert {
          margin-top: $spacer / 2;

          &:first-of-type {
            margin-top: $spacer;
          }
        }

        table {
          background-color: $bg-light;
        }
      }
    }
  }
}