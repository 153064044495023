.email_subscription {
  @include screen-md-max {
    margin-bottom: $spacer / 2;
  }

  @include screen-sm-max {
    margin-bottom: $spacer;
  }

  h3 {
    margin: 0 0 10px 0;
  }

  p {
    line-height: normal;

    @include screen-md-min {
      background-color: rgba($brand-primary, 0.5);
    }
  }

  #mc_embed_signup {
    @include screen-sm-max {
      background: $brand-primary;
      padding: 15px;
    }
  }

  form {
    input.email {
      border: none;
    }
  }

  .button {
    margin-top: $spacer / 4;
  }

  .notifications__single {
    margin: 0;

    ul {
      li {
        margin-bottom: 15px;
      }
    }
  }
}