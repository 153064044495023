@mixin checkbox($color, $type: "checkbox") {
  [type="#{$type}"]:not(:checked),
  [type="#{$type}"]:checked {
    position: absolute;
    // Stops js validation from working
    //left: -9999px;
    // Use opacity instead
    opacity: 0;
  }

  [type="#{$type}"]:not(:checked) + label,
  [type="#{$type}"]:checked + label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
  }

  /* checkbox */

  [type="#{$type}"]:not(:checked) + label:before,
  [type="#{$type}"]:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;

    @if $type == "radio" {
      border-radius: 50%;
    } @else {
      border-radius: $border-radius;
    }

    border: $border-width solid $border-color;
    background: $white;
  }

  /* checked mark */

  [type="#{$type}"]:not(:checked) + label:after,
  [type="#{$type}"]:checked + label:after {
    content: "";
    background-color: $brand-primary;
    position: absolute;
    width: 18px;
    height: 18px;

    content: "\e5ca";
    font-family: $font-family-icon;
    font-size: 12px;
    text-align: center;
    line-height: 18px;
    color: $white;
    font-weight: $font-bold;


    @if $type == "radio" {
      border-radius: 50%;
    } @else {
      border-radius: $border-radius;
    }

    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  /* checked mark changes */

  [type="#{$type}"]:not(:checked) + label:after {
    opacity: 0;
  }

  [type="#{$type}"]:checked + label:after {
    opacity: 1;
  }
}
