button,
.button {
  appearance: none;
  -webkit-appearance: none;
  font-weight: $font-bold;
  height: $element-height;
  line-height: $element-height;
  padding: 0 10px;
  text-align: center;
  border: 0;
  display: inline-block;
  outline: 0;
  border-radius: $border-radius;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &[disabled] {
    opacity: 0.2;
    cursor: not-allowed;
  }

  &.button--primary {
    @include backgroundHover($brand-primary);
    color: $white;
  }

  &.button--primary-dark {
    @include backgroundHover($brand-primary-dark);
    color: $white;
  }

  &.button--secondary {
    @include backgroundHover($brand-secondary);
  }

  &.button--tertiary {
    @include backgroundHover($brand-tertiary);
     color: $white;
  }

  &.button--cancel {
    @include backgroundHover(#b0343b);
    color: $white;
  }

  &.button--success {
    @include backgroundHover(#249417);
    color: $white;
  }

  &.button--full-width {
    &-desktop {
      width: 100%;
    }

    @include screen-xs {
      display: block;
      width: 100%;
      text-align: center;
    }
  }

  &.button--uppercase {
    text-transform: uppercase;
  }

  &.button--small {
    font-size: 0.8rem;
    height: $element-height - 10px;
    line-height: $element-height - 10px;

    i {
      font-size: 0.9rem;
    }
  }

  &.button--large {
    font-size: $font-size + 0.2rem;
    height: $element-height + 10px;
    line-height: $element-height + 10px;

    i {
      font-size: $font-size;
    }
  }

  &.back-to-login {
    margin-right: 15px;
  }
}

.btn {
  appearance: none;
  -webkit-appearance: none;
  font-weight: $font-bold;
  height: $element-height;
  line-height: $element-height;
  padding: 0 10px;
  text-align: center;
  border: 0;
  display: inline-block;
  outline: 0;
  border-radius: $border-radius;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &[disabled] {
    opacity: 0.2;
    cursor: not-allowed;
  }

  &-primary {
    @include backgroundHover($brand-primary);
    color: $white;
  }

  &-secondary {
    @include backgroundHover($brand-secondary);
  }

  &-tertiary {
    @include backgroundHover($brand-tertiary);
    color: $white;
  }

  i {
    &.material-icons {
      font-size: 18px;
      line-height: $element-height;
      margin: 0 10px 0;
    }
  }
}