.header {
  background-color: $white;

  @include screen-md-max {
    position: fixed;
    width: 100%;
    z-index: $z-index2;
    border-bottom: 1px solid #eee;
  }

  .left__column {
    @media (max-width: 479px) {
      padding-right: 0;
    }
  }

  &__top {
    position: relative;

    @include screen-md {
      max-width: 100%;
    }

    @include screen-sm-max {
      min-height: 60px;
    }

    @include screen-xs {
      min-height: 50px;
    }

    @media (max-width: 420px){
      min-height: 40px;
    }
  }

  &__contactblock {
    @include screen-lg-min {
      margin-top: 65px;
    }

    h4 {
      font-family: $font-family-jenna;
      color: $brand-primary;
      line-height: 1em;
      font-size: $font-size * 2.5;
      font-style: italic;
      margin: 0;

      a {
        color: $brand-primary;
        font-weight: $font-weight;
        background-color: $white;
      }
    }

    p {
      //font-weight: $font-bold;
      margin: 0;

      a {
        color: $font-color;
      }
    }
  }

  &__logo {
    &-wrapper {
      @include screen-md-max {
        padding: 0;
      }
    }

    a {
      img {
        margin: 0 auto;
        display: block;
      }
    }

    &-mobile {
      a {
        img {
          @include screen-sm-max {
            max-width: 270px;
          }

          @include screen-xs {
            max-width: 220px;
          }

          @media (max-width: 420px) {
            max-width: 170px;
          }
        }
      }
    }
  }

  .search-box-toggle {
    height: 25px;
    width: 25px;
    background: url('#{$assets_dir}img/search.svg') 50% 50% no-repeat !important;
    background-size: contain;
    vertical-align: top;
    position: absolute;
    left: 65px;
    top: 35px;

    @include screen-sm-max {
      left: 65px;
      top: 22px;
      width: 20px;
      height: 20px;

    }

    @include screen-xs {
      top: 17px
    }

    @media (max-width: 420px){
      left: 50px;
      top: 9px
    }
  }

  .mobile_menu_link {
    vertical-align: top;
    margin-right: 20px;

    @include screen-xs {
      margin-right: 10px;
    }

    .mobile_menu_wrapper {
      margin-top: 30px;

      @include screen-sm-max {
        margin-top: 20px;
      }

      @media (max-width: 479px) {
        margin-top: 17px;
      }

      @media (max-width: 420px) {
        margin-top: 10px;
      }
    }

    .icon-bar {
      background-color: $brand-primary;
      display: block;
      margin: 0 0 5px 0;
      width: 25px;
      height: 3px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .top_blocks {
    margin-top: $gutter;
    margin-bottom: -35px;

    div {
      &:first-child {
        padding-right: 2.5px;
      }

      &:last-child {
        padding-left: 2.5px;
      }
    }
  }

}

