@import "includes/breakpoints";
@import "includes/elements";
@import "includes/transition";
@import "includes/animations";

@mixin textHover($color){
  color: $color;

  &:hover {
    color: darken($color, 15%);
  }
}

@mixin backgroundHover($color){
  background-color: $color;

  &:hover {
    background-color: darken($color, 15%);
  }
}

@mixin boxShadow($p1, $p2, $p3, $p4, $color) {
  box-shadow: $p1 $p2 $p3 $p4 $color;
  -webkit-box-shadow: $p1 $p2 $p3 $p4 $color;
  -moz-box-box-shadow: $p1 $p2 $p3 $p4 $color;
}