label {
  display: inline-block;
  margin-bottom: $spacer / 4;
  cursor: pointer;

  &.required {
    &:after {
      content: "*";
      color: red;
      display: inline-block;
    }
  }
}
