.cart-overview {
  .body {
    background-color: $white;
    border-radius: $border-radius;
    padding: $spacer / 2;
    margin-bottom: $spacer * 0.75;
    border: $border-width solid $border-color;

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style-type: none;

        &.cart-item {
          border-bottom: $border-width solid $border-color;
          padding: $spacer / 2 0;
          // display: flex;
          // justify-content: space-between;
          // align-items: center;

          &:first-of-type {
            padding-top: 0;
          }

          &:last-of-type {
            border-bottom: 0;
            padding-bottom: 0;
          }
        }

        .product-image {
          img {
            display: block;

            @include screen-md-max {
              width: 100%;
            }
          }

          @include screen-md-max {
            margin-bottom: $spacer / 2;
          }
        }

        .product-name-wrapper {
          .product-name {
            display: block;
          }

          .product-attributes {
            color: $gray;
            font-size: $font-size * 0.9;
            clear: both;
            display: block;
          }

          @include screen-md-max {
            margin-bottom: $spacer / 2;
          }
        }

        .product-quantity-wrapper {
          a {
            vertical-align: top;
            width: $element-height * 0.8;
            height: $element-height * 0.8;
            position: relative;

            i {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }

          .product-quantity {
            display: inline-block;
            vertical-align: top;
            width: $element-height * 0.8;
            text-align: center;
            line-height: $element-height * 0.8;
          }

          @include screen-md-min {
            text-align: right;
          }
        }

        .product-price {
          text-align: right;
          white-space: nowrap;
        }

        .remove-from-cart {
          display: block;
          
          i {
            pointer-events: none;
          }
        }
      }
    }
  }

  .continue_shopping {
    margin-bottom: $spacer * 0.75;
  }
}
