#contact {
  .contact-form {
    h3 {
      font-size: $h4-font-size;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: $border-width dashed $border-color;
    }

    background-color: $bg-light;
    margin-bottom: $spacer / 2;
    padding: $spacer;

    label {
      width: 100%;
      margin-bottom: 15px;
      font-weight: $font-bold;

      span {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
      }
    }

    textarea {
      height: 300px;
      resize: none;
      border-radius: 0;
    }
  }
}