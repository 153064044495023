$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-el: 1200px;

@mixin small-mobile {
  @media (max-width: 479px) {
    @content;
  }
}

@mixin screen-xs {
  @media (max-width: #{$screen-sm - 1px}) {
    @content;
  }
}

@mixin screen-sm {
  @media (min-width: #{$screen-sm}) and (max-width: #{$screen-md - 1px}) {
    @content;
  }
}

@mixin screen-sm-min {
  @media (min-width: #{$screen-sm}) {
    @content;
  }
}

@mixin screen-sm-max {
  @media (max-width: #{$screen-md - 1px}) {
    @content;
  }
}

@mixin screen-md {
  @media (min-width: #{$screen-md}) and (max-width: #{$screen-lg - 1px}) {
    @content;
  }
}

@mixin screen-md-min {
  @media (min-width: #{$screen-md}) {
    @content;
  }
}

@mixin screen-md-max {
  @media (max-width: #{$screen-lg - 1px}) {
    @content;
  }
}

@mixin screen-lg {
  @media (min-width: #{$screen-lg}) and (max-width: #{$screen-el - 1px}) {
    @content;
  }
}

@mixin screen-lg-min {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}

@mixin screen-lg-max {
  @media (max-width: #{$screen-el - 1px}) {
    @content;
  }
}

@mixin screen-el {
  @media (min-width: #{$screen-el}) {
    @content;
  }
}
