#search_filters_wrapper {
  margin-bottom: 30px;

  h4 {
    background-color: $bg-medium;
    border-bottom: $border-width solid $white;
    color: darken($font-color, 5%);
    font-size: $font-size;
    font-family: $font;
    line-height: normal;
    margin: 0;
    padding: 10px 40px 10px 15px;
    position: relative;

    &.active {
      border-bottom: none;
    }

    &:hover {
      cursor: pointer;
    }

    &.title {
      background-color: $brand-tertiary;
      //border-bottom: $border-width solid $white;
      color: $white;
      text-transform: uppercase;

      &:hover {
        cursor: initial;
      }

      i {
        position: relative;
        margin-right: 10px;
      }
    }

    i {
      pointer-events: none;
      position: absolute;
      right: 10px;
    }
  }
}

#category-filter {
  height: $element-height + 10px;
  line-height: $element-height + 10px;
  text-transform: uppercase;
}

#search_filters {
  @include screen-sm-max {
    position: fixed;
    display: block;
    top: 0;
    left: -320px;
    bottom: 0;
    background-color: #fff;
    z-index: 50;
    width: 300px;
    transition: left .3s ease;
    padding: 15px;
    overflow-y: scroll;

    &.visible {
      left: 0;
    }
  }

  @include small-mobile {
    left: -270px;
    width: 270px;
  }

  ul {
    border-left: $border-width solid $border-color-medium;
    border-right: $border-width solid $border-color-medium;
    list-style-type: none;
    margin: 0;

    li {
      line-height: normal;
      padding: 10px;

      label {
        font-weight: normal;
        margin-bottom: 0;
      }

      a {
        color: $font-color;
      }

      span.magnitude {
        font-size: 0.7rem;
      }
    }
  }
}

.search_filters {
  &__overlay {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .5);
    z-index: 45;
    transition: opacity .3s ease;
    cursor: pointer;

    &--visible {
      @include screen-sm-max {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

// Facet Sections
section.facet {

  &:last-of-type {
    ul {
      border-bottom: $border-width solid $border-color;
    }
  }

  ul {
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;

    &.active {
      height: auto;
      opacity: 1;
      visibility: visible;
      padding: 10px;
    }
  }
}

// Active Filters
#js-active-search-filters {
  margin-bottom: 20px;

  &.active {
    display: block;
  }

  h4 {
    color: $white;
    font-size: $h6-font-size;
    line-height: normal;
    margin: 0;
    padding: 10px 15px;
    position: relative;

    &.title {
      background-color: $brand-tertiary;
    }
  }

  .inner {
    border: $border-width solid #f1e0da;
    border-top: none;
    max-width: 100%;
  }

  ul {
    border: none;
    list-style-type: none;
    margin: 0;
    padding: 15px;

    li {
      border-bottom: $border-width solid $border-color-medium;
      margin-bottom: 10px;
      padding: 0 40px 10px 0;
      position: relative;

      &:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      a {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  .clear-all-filters {
    @include backgroundHover(#f1e0da)
    margin: 0;
    padding: 5px 10px;
    text-align: center;

    a:hover {
      text-decoration: none;
    }
  }

  &.hide {
    @extend .d-none;
  }
}