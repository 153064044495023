.js-checkout-summary {
  .js-checkout-summary-inner {
    border: $border-width solid $border-color;
    padding: $spacer / 2;
    margin-bottom: $spacer / 2;
    background-color: $white;
    border-radius: $border-radius;

    header {
      border-bottom: $border-width solid $border-color;
      display: flex;
      justify-content: space-between;
      padding-bottom: $spacer / 2;

      p {
        margin: 0;
      }
    }

    #cart-summary-product-list {
      border-bottom: $border-width solid $border-color;
      //margin-bottom: $spacer / 2;
      padding: $spacer / 2 0;
      //overflow: hidden;

      //&.scroll {
      //  max-height: 240px;
      //  overflow-y: scroll;
      //}

      ul {
        margin: 0;
        padding: 0;

        li {
          list-style-type: none;
          display: flex;
          margin-bottom: $spacer / 2;

          &:last-of-type {
            margin-bottom: 0;
          }

          .product-image {
            width: 90px;
            align-self: center;
          }

          .product-info {
            margin-left: $spacer / 4;
            display: block;

            .product-name {
                margin: 5px 0;

              @include screen-lg-max {
                white-space: nowrap;
              }
            }

            .product-name,
            .product-price,
            .product-quantity {
              display: block;
            }
          }
        }
      }
    }

    .cart-summary-totals {
      margin-top: $spacer / 2;
    }

    .notifications__single {
      margin: 0;
      text-align: center;
    }
  }

  @include screen-md-max {
    margin-bottom: $spacer * 0.75;
  }
}
