.conf-block {
  &__inner {
    background-color: white;
    border-radius: $border-radius;
    border: $border-width solid $border-color;
    padding: $spacer;

    @include screen-xs {
      padding: $spacer / 2;
    }
  }

  #order-message {
    h4 {
      margin: 0;
    }

    a {
      font-weight: $font-bold;
      text-decoration: underline;
    }
  }

  #order-details {
    border-top: $border-width solid $border-color;
    border-bottom: $border-width solid $border-color;
    padding: $spacer / 2 0;

    ul {
      padding: 0;
      margin: 0;
      font-size: 0;

      li {
        font-size: initial;
        width: 25%;
        display: inline-block;
        vertical-align: top;
        list-style-type: none;

        .order-details__label {
          display: block;
          color: $gray;
          text-transform: capitalize;
        }

        .order-details__value {
          display: block;
          font-weight: bold;
        }

        @include screen-md-max {
          width: 50%;
        }

        @include screen-xs {
          width: 100%;
          display: block;
          text-align: center;
        }
      }
    }
  }

  #order-items {
    .order-items__product {
      padding: $spacer / 2 0;
      border-bottom: $border-width solid $border-color;

      .order-items__name {
        > p {
          margin: 0;
        }

        .order-items__attributes {
          padding: 0;
          margin: 0;

          li {
            color: $gray;
            list-style-type: none;
            font-size: $font-size * 0.9;
            line-height: 20px;
          }
        }
      }

      .order-items__image {
        img {
          display: block;

          @include screen-sm-max {
            width: 100%;
          }
        }
      }

      .order-items__quantity {
        text-align: center;

        @include screen-xs {
          text-align: left;
        }
      }

      .order-items__price {
        text-align: right;
      }
    }

    .order-items__label {
      color: $gray;
    }

    table {
      tr {
        border-bottom: $border-width solid $border-color;

        &:last-of-type {
          border-bottom: 0;
        }

        td {
          padding: $spacer / 4 0;
        }
      }
    }
  }

  @include screen-xs {
    padding: $spacer / 2 15px;
  }
}
