.notifications {
  &__single {
    margin: ($spacer / 2) 0;
    border-radius: $border-radius;

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      //color: $white;
      list-style-type: none;
      padding: ($spacer / 4) ($spacer / 2);
      border: $border;
    }

    a {
      color: inherit;
      font-weight: $font-bold;
      text-decoration: underline;
    }

    &--success {
      background-color: $notification-success;
      color: #3c763d;

      li {
        border-color: darken($notification-success, 40%);
      }
    }

    &--danger {
      background-color: $notification-danger;
      color: #a94442;

      li {
        border-color: darken($notification-danger, 40%);
      }
    }

    &--warning {
      background-color: $notification-warning;
      color: #8a6d3b;

      li {
        border-color: darken($notification-warning, 40%);
      }
    }

    &--info {
      background-color: $notification-info;
      color: #31708f;

      li {
        border-color: darken($notification-info, 40%);
      }
    }

    .js-error-text {
      background-color: $notification-danger;
      color: #a94442;
      border-color: darken($notification-danger, 40%);
    }
  }
}

// Standard Bootstrap 3 alerts
.alert {
  margin: ($spacer / 2) 0;
  border-radius: $border-radius;
  border: $border;
  padding: ($spacer / 4) ($spacer / 2);

  &-danger {
    background-color: $notification-danger;
    color: #a94442;
    border-color: darken($notification-danger, 40%);
  }

  &-success {
    background-color: $notification-success;
    color: #3c763d;
    border-color: darken($notification-success, 40%);
  }

  &-warning {
    background-color: $notification-warning;
    color: #8a6d3b;
    border-color: darken($notification-warning, 40%);
  }

  &-info {
    background-color: $notification-info;
    color: #31708f;
    border-color: darken($notification-info, 40%);
  }
}