.checkout-step {
  height: 45px;
  margin-bottom: $spacer / 2;
  border-radius: $border-radius;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }

  &.-reachable {
    > h1 {
      &:hover {
        cursor: pointer;
        background-color: darken($bg-dark, 10%);
      }
    }

    &.-complete {
      > h1 {
        background-color: $brand-primary;
        position: relative;

        &:after {
          content: '\e86c';
          font-family: $font-family-icon;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }

        &:hover {
          //cursor: default;
          background-color: darken($brand-primary, 10%);
        }
      }
    }
  }

  &.-current {
    height: auto;

    > h1 {
      background-color: $bg-dark !important;
      position: relative;

      //&:after {
      //  content: '\e312' !important;
      //  font-family: $font-family-icon;
      //  position: absolute;
      //  right: 10px;
      //  top: 10px;
      //  //top: 50%;
      //  //transform: translateY(-50%);
      //}

      &:hover {
        cursor: default;
        background-color: darken($bg-dark, 10%);
      }
    }
  }

  &.-unreachable {
    cursor: not-allowed;
    opacity: 0.3;
  }

  h1 {
    padding: 10px;
    background-color: $bg-dark;
    display: block;
    margin: 0;
    color: $white;
    font-size: $font-size * 1.2;
    font-weight: $font-bold;
    font-family: $font;
    line-height: normal;

    &:after {
      font-size: 22px;
    }

    .step-number {
      font-style: italic;
    }

    a {
      color: $white;
      font-size: initial;
      font-style: italic;
      float: right;
      margin-right: $spacer;

      @include screen-sm-max {
        float: none;
        margin: 10px 0 0 0;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    @include screen-xs {
      font-size: $font-size * 1.2;
      padding: 10px 15px;
    }
  }

  .content {
    border: $border-width solid $border-color;
    border-top: none;
    padding: 15px;
    background-color: $white;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
}

#checkout-personal-information-step {
  .notifications__single {
    margin: ($spacer / 2) 0
  }
}

// IE fix for flex-column-reverse on mobile
#checkout_info,
.js-cart {
  flex: 1 0 auto;
}

// Voucher Codes
//.cart-voucher {
//  border-top: $border;
//  padding: 15px 0;
//
//  ul {
//    list-style-type: none;
//    padding: 0;
//
//    li {
//
//      //background-color: $bg-light;
//      //padding: 10px 80px 10px 10px;
//      //border: $border;
//      //line-height: normal;
//      //min-height: 45px;
//      //position: relative;
//
//      //a {
//      //  position: absolute;
//      //  right: 5px;
//      //  top: 50%;
//      //  transform: translateY(-50%);
//      //}
//    }
//  }
//
//  // Notifications
//  .notification {
//    line-height: 1.5em;
//  }
//}