#blockcart-modal {
  &.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    max-width: 100%;
    width: 800px;
    height: 310px;
    z-index: $z-index5;
    background-color: $white;
    border-radius: $border-radius;
    overflow: hidden;
    //border: $border-width solid $border-color;
    animation: fadeIn;
    animation-duration: 0.3s;
    box-shadow: 0 0 30px -2px rgba(0, 0, 0, 0.3);

    @include screen-md-max {
      width: 600px;
    }

    @include screen-sm-max {
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      max-height: 430px;
      margin: 0 auto;
      left: 15px;
      top: 15px;
      transform: none;
      overflow: scroll;
    }
  }

  .modal {
    &__header {
      text-align: center;
      line-height: $spacer;

      h4 {
        margin: 0;
        font-weight: normal;
        font-size: $font-size;
      }
    }

    &__body {
      display: flex;

      @include screen-sm-max {
        display: block;
      }
    }

    &__image {
      width: 50%;
      float: left;

      img {
        border: $border;

        @include screen-xs {
          max-width: 100px;
        }
      }

      &--padding-left {
        padding-left: $spacer / 2;
      }
    }

    &__product-name {
      margin: 0 0 $spacer / 4 0;
    }

    &__half {
      width: 50%;
      padding: $spacer * 0.75;

      &--clearfix {
        &:after {
          content: '';
          display: table;
          width: 100%;
        }
      }

      &:first-of-type {
        border-right: $border-width solid $border-color;
      }

      @include screen-sm-max {
        width: 100%;
        padding: $spacer / 2;

        &:first-of-type {
          border-bottom: $border-width solid $border-color;
        }
      }
    }

    &__count {
      margin: 0;
    }

    &__pricing {
      margin: $spacer / 2 0;
    }

    &__actions {
      &:after {
        content: '';
        display: table;
        width: 100%;
      }

      .continue {
        float: left;
      }

      .checkout {
        float: right;
      }
    }
  }
}

.modal {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: $z-index4;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    top: calc(50% + 200px);
  }
  to {
    opacity: 1;
    top: 50%;
  }
}