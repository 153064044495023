.angelalangford_banners {
  margin-bottom: $spacer / 2;

//   @include screen-md-min {
//     margin-top: $gutter;
//   }

  @include screen-sm-max {
    margin-bottom: $spacer / 4;
  }

  #index & {
    margin-bottom: $gutter;
  }

  .banner__container {
    .banner {
      height: $spacer;
      font-family: $font-family-questa;
      font-size: 1.3em;
      font-weight: bold;
      padding: 3px 5px;
      letter-spacing: 0.03em;
      line-height: 30px;
      text-align: center;
      text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
      margin-bottom: $gutter * 2;

      @include screen-sm-max {
        font-size: 1rem;
        height: $spacer - 5px;
        line-height: 25px;
      }

      &__image {
        @include backgroundHover($brand-primary);
        color: $white;

        img {
          display: inline-block;
          height: 100%;
          margin: 0 10px 0 0;
        }
      }

      &__text-only {
        color: #000;
        font-family: $font-family-droid;
        text-shadow: none;
        font-size: $font-size;
        font-weight: $font-weight-normal;
        height: auto;
        line-height: 1.4em;
        padding: 10px;
        @include backgroundHover($white);
      }
    }

    &.banner {
      &__image-left.col-md-6 {
        padding-right: $gutter / 2;

        @include screen-sm-max {
          padding-right: 15px;
          margin-bottom: $gutter;
        }
      }

      &__image-right.col-md-6 {
        padding-left: $gutter / 2;

        @include screen-sm-max {
          padding-left: 15px;
        }
      }
    }
  }
}